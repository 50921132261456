import React, { Component } from "react"
import { graphql } from "gatsby"
import "queries/defaultTemplateQuery"
import Layout from "components/Layout/Layout"
import FlexibleContent from "components/FlexibleContent/FlexibleContent"

class DefaultTemplate extends Component {
    render() {
        const data = this.props.data.wpPage

        console.log(data)
        const acfBlocks = data.template.acfFlexibleContent.blocks
        const googleAds = data.acfGoogleAds
        const { title, metaDesc }  = data.seo
        return (
            <Layout title={title} description={metaDesc} path={data.uri} googleAds={googleAds}>
                <FlexibleContent blocks={acfBlocks} />
            </Layout>
        )
    }
}

export const pageQuery = graphql`
query($id: String!) {
	wpPage(id: { eq: $id }) {
        title
        uri
        seo {
            title
            metaDesc
        }
        acfGoogleAds {
            bellybandName
        }
        template {
            ... on WpDefaultTemplate {
              ...defaultTemplateQuery
            }
        }
    }
}
`

export default DefaultTemplate