import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
// import LazyLoad from 'react-lazy-load'

const GridHubItemWrap = styled.div`
    cursor: pointer;
    position: relative;
    background-color: white;

    button {
        padding: 0;
        border: 0;
        width: 100%;
    }
`

const ContentHubItem = (props) => {
    let size = props.size

    return (
        <GridHubItemWrap style={size}>
            <button onClick={() => props.onClick()}>
                {/* <LazyLoad
                    offsetVertical={200}
                >
                    <img src={props.thumbnail.sourceUrl} alt={props.altText} />
                </LazyLoad> */}
                <GatsbyImage
                    image={props.thumbnail.localFile.childImageSharp.gatsbyImageData}
                    alt={props.altText} />
            </button>         
        </GridHubItemWrap>
    );
} 

export default ContentHubItem