import React from "react"
import { StaticQuery, graphql } from "gatsby";
import ContainerMax from "components/shared/ContainerMax";
import styled from "styled-components";
import { media } from "utils/Media";
import Social from "components/shared/Social"
import { GatsbyImage } from "gatsby-plugin-image";

const SocialImages = styled.div`
	/* margin-bottom: 50px; */
	text-align: center;		
    /* @media ${media.md} {
		margin-bottom: 114px;
    }	    
    @media ${media.lg} {
		margin-bottom: 134px;
    } */
	h4 {
		text-transform: uppercase;
	}

	h5 {
		font-family: ${props => props.theme.font.family.base};
	}

	.images {
		font-size: 0;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-content: flex-start;
	    @media ${media.md} {
			width: 528px;
			margin: 0 auto;
		}
	    @media ${media.lg} {
			width: 996px;
			position: relative;
	    }
		.gatsby-image-wrapper {	
			display: none;
			margin-bottom: 12px;
		    @media ${media.md} {
				margin-bottom: 24px;
			}
			/* > div {
				padding-bottom: 100%;
			} */
			
			&.size1 {
				width: calc(50% - 6px);
			    @media ${media.md} {
					width: 160px;
				}
			    @media ${media.lg} {
					width: 180px;
					height: 180px;
			    }			
			}
			&.size2 {    
				width: calc(50% - 6px);
			    @media ${media.md} {
					width: 252px;
				}
			    @media ${media.lg} {
					width: 282px;
			    }
			}
			&.size3 {    
				width: calc(50% - 6px);
			    @media ${media.md} {
					width: 68px;
					height: 68px;
				}
			    @media ${media.lg} {
					width: 78px;
					height: 78px;
			    }		
			}
			&.image2,
			&.image3,
			&.image8,
			&.image9 {
				display: inline-block;
			}
		    @media ${media.md} {
				&.image7,
				&.image10 {
					display: inline-block;
			    }		
			}	
		    @media ${media.lg} {
				display: inline-block;				
				&.image5 {
					position: absolute !important;
					top: 204px;
					left: 102px;
				}
				&.image6 {
					position: absolute !important;
					top: 204px;
					right: 102px;
				}				
		    }		
		}
		@media ${media.lg} {
			&.row2 {
				width: 588px;
			}
		}
	}
`

const SocialStyled = styled.div`
	text-align: center;
	margin-bottom: 40px;

	ul {
		li {
			a {
				display: inline-block;
				margin: 0 10px;
				font-size: 1.5rem;
				color: ${props => props.theme.colors.secondary};

				&:hover {
					color: ${props => props.theme.colors.primary};
				}

				svg {
					max-width: 30px;
					max-height: 30px;
				}
			}
		}
	}
`
export default () => (
    <StaticQuery
        query={graphql`{
  allWp {
    edges {
      node {
        options {
          acfOptions {
            blockSocialImages {
              title
              subtitle
              image1 {
                altText
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 723, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
              }
              image2 {
                altText
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 723, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
              }
              image3 {
                altText
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 723, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
              }
              image4 {
                altText
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 723, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
              }
              image5 {
                altText
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 723, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
              }
              image6 {
                altText
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 723, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
              }
              image7 {
                altText
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 723, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
              }
              image8 {
                altText
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 723, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
              }
              image9 {
                altText
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 723, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
              }
              image10 {
                altText
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 723, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`}
        render={data => {
			const block = data.allWp.edges[0].node.options.acfOptions.blockSocialImages;
			
            return (
                <ContainerMax className="py-5">
                	<SocialImages>
        	        	<h4 dangerouslySetInnerHTML={{ __html: block.title }} />            
        	        	<h5 dangerouslySetInnerHTML={{ __html: block.subtitle }} />

						<SocialStyled>
							<Social />
						</SocialStyled>
        		           	   
        	        	<div className="images">
	        	        	{ block.image1 ? <GatsbyImage
                                image={block.image1.localFile.childImageSharp.gatsbyImageData}
                                className="image1 size1"
                                alt={block.image1.altText} /> : null }   
	        	        	{ block.image2 ? <GatsbyImage
                                image={block.image2.localFile.childImageSharp.gatsbyImageData}
                                className="image2 size2"
                                alt={block.image2.altText} /> : null }
	        	        	{ block.image3 ? <GatsbyImage
                                image={block.image3.localFile.childImageSharp.gatsbyImageData}
                                className="image3 size2"
                                alt={block.image3.altText} /> : null }
	        	        	{ block.image4 ? <GatsbyImage
                                image={block.image4.localFile.childImageSharp.gatsbyImageData}
                                className="image4 size1"
                                alt={block.image4.altText} /> : null }
	        	        	{ block.image5 ? <GatsbyImage
                                image={block.image5.localFile.childImageSharp.gatsbyImageData}
                                className="image5 size3"
                                alt={block.image5.altText} /> : null }
	        	        	{ block.image6 ? <GatsbyImage
                                image={block.image6.localFile.childImageSharp.gatsbyImageData}
                                className="image6 size3"
                                alt={block.image6.altText} /> : null }
	        	        </div>
	        	        <div className="images row2">
	        	        	{ block.image7 ? <GatsbyImage
                                image={block.image7.localFile.childImageSharp.gatsbyImageData}
                                className="image7 size3"
                                alt={block.image7.altText} /> : null }
	        	        	{ block.image8 ? <GatsbyImage
                                image={block.image8.localFile.childImageSharp.gatsbyImageData}
                                className="image8 size1"
                                alt={block.image8.altText} /> : null }
	        	        	{ block.image9 ? <GatsbyImage
                                image={block.image9.localFile.childImageSharp.gatsbyImageData}
                                className="image9 size1"
                                alt={block.image9.altText} /> : null }
	        	        	{ block.image10 ? <GatsbyImage
                                image={block.image10.localFile.childImageSharp.gatsbyImageData}
                                className="image10 size3"
                                alt={block.image10.altText} /> : null }
        	        	</div>  
                	</SocialImages>
                </ContainerMax>
            );
        }}
    />
)