import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Container } from "reactstrap";
import PlayerProfile from "components/shared/PlayerProfile";
import styled from "styled-components";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { media } from "utils/Media";
import WPLink from "components/shared/WPLink";
import BlockTitle from "components/shared/BlockTitle"

const PlayerProfileCarousels = styled(Container)`
	margin-bottom: 37px;	
    @media ${media.md} {
		margin-bottom: 50px;
    }	
    @media ${media.lg} {
		margin-bottom: 70px;
    }	
	.slick-slider { 
		padding-bottom: 20px;
		margin-bottom: 20px;
		.slick-list {
			margin: 0 -6px;
		    @media ${media.md} {
				margin: 0 -12px;	
		    }	 
		}
		.slick-slide {
			padding: 0 6px;	
		    @media ${media.md} {
				padding: 0 12px;
		    }
		    article {
		    	cursor: default;
		    	margin-bottom: 0;
		    }
		}
		.solheim-arrow {
			position: absolute;
			bottom: -14px;
			background: transparent;
			appearance: none;
			border: none;
			font-size:24px;
			z-index: 10;
			padding: 0;
            color: ${props => props.theme.colors.secondary};

		    @media ${media.md} {
		    	bottom: auto;
		    	top: 50%;
				transform: translateY(-50px);
		    }

			&.prev {
				left: 0;
			    @media ${media.md} {
					left: -20px;
			    }
			    @media ${media.lg} {
			    	left: -70px;
			    }
			}
			&.next {
				right: 0;
			    @media ${media.md} {
					right: -20px;
			    }
			    @media ${media.lg} {
			    	right: -70px;
			    }
			}
		}
		.slick-dots {
			height: 10px;
			line-height: 10px;
			bottom: 0;
			&:before,
			&:after {
				content: '';  
				width: 6px;
				height: 6px;
				background: ${props => props.theme.colors.primary};
				border-radius: 50%;
				display: inline-block;
				vertical-align: middle;
				margin: 0 2.5px;
			}
			li {
				margin: 0 2.5px;
				width: auto;
				height: auto;
				vertical-align: middle;
				button {
					width: 10px;
					height: 10px;
					background: #323741;
					border-radius: 50%;
					&:before {
					  display: none;
					}
				}
				&.slick-active button {
					background: ${props => props.theme.colors.secondary};
				}
			}
		}
	}
`

export default ({ block }) => (
    <StaticQuery
        query={graphql`
            query {
                allWpPlayer {
                        edges {
                            node {
                                title
                                featuredImage {
                                    node {
                                        altText
                                        sourceUrl
                                        mediaDetails {
                                            height
                                            width
                                            sizes {
                                                height
                                                width
                                                sourceUrl
                                                name
                                            }
                                        }
                                    }
                                }
                                acfPlayers {
                                    age
                                    playerDob
                                    bestAtpRating
                                    noOfTitles
                                    pgaWins
                                    usOpenWins
                                    turnedPro
                                    bio
                                    facebook
                                    twitter
                                    instagram
                                }
                                playercategories {
                                    nodes {
                                        databaseId
                                    }   
                                }
                                nationalities {
                                    nodes {
                                        databaseId
                                    }   
                                }
                            }
                        }
                    } 	
                    allWpNationality {
                        edges {
                            node {
                                databaseId
                                name
                                slug
                                acfPlayerNationalities {
                                    flag {
                                        sourceUrl
                                    }
                                }
                            }
                        }    
                    }		  
                
            }
        `}
        render={data => {
            const { title, categories } = block
            let players = data.allWpPlayer.edges
            let nationalities = data.allWpNationality.edges
            let flag

            // Filter for categories
            if (categories.length > 0) {
                players = players.filter(player => {
                    let r = false
                    categories.forEach(cat => {
                        player.node.playercategories.edges.forEach(playerCat => {
                            if (playerCat.node.databaseId === cat.databaseId) {
                                r = player
                            }
                        })
                    });
                    return r
                })    
            }
            
        	function PrevButton({ onClick }) {
        	      return <button onClick={onClick} className="solheim-arrow prev"><FontAwesomeIcon icon={faChevronLeft} /></button>;
        	}

    	    function NextButton({ onClick }) {
    	      return <button onClick={onClick} className="solheim-arrow next"><FontAwesomeIcon icon={faChevronRight} /></button>;
    	    }
    	    
        	//slider settings
            var settings = {
      	        dots: true,
      	        infinite: false,
      	        speed: 500,
      	        slidesToShow: 6,
      	        slidesToScroll: 6,
      	        prevArrow: <PrevButton />,
      	        nextArrow: <NextButton />,
      	        responsive: [
	      	        {
	      	            breakpoint: 1199,
	      	            settings: {
	      	                slidesToShow: 4,
	      	                slidesToScroll: 4
	      	            }
	      	        },
	      	        {
	      	            breakpoint: 767,
	      	            settings: {
	      	                slidesToShow: 2,
	      	                slidesToScroll: 2
	      	            }
	      	        }
      	        ]
              };
              
            return (
               <PlayerProfileCarousels>
                    {title && 
                        <BlockTitle base>{title}</BlockTitle>
                    }
                    <Slider {...settings}>
                        {players && players.map((player, j) => {
                            nationalities.forEach(({ node }) => {
                                if (player.node.nationalities && player.node.nationalities.edges[0].node.databaseId) {
                                    flag = node.acfPlayerNationalities !== null ? node.acfPlayerNationalities.flag : false
                                }
                            })
                            return(
                                <PlayerProfile
                                    key={j}
                                    data={player.node}
                                    id={j}
                                    statsToggled={false}
                                    dropdownInfo={false}
                                    flag={flag ? flag.sourceUrl : ""}
                                />
                            )
                        })}
                    </Slider>
		            {block.button.url !== "" &&
                        <div className="text-center">
                            <WPLink url={block.button.url} target={block.button.target} button>
                                {block.button.title}
                            </WPLink>
                        </div>
                    }
                </PlayerProfileCarousels>
            )
        }}
    />
)