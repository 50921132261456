import React, { Component } from "react";
import { graphql, StaticQuery } from "gatsby";
import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";
import NewsPreview from "components/shared/NewsPreview";

const NewsArticle = styled.article`
	margin: 0 0 115px;
	header {
		h1 {
			font-size: ${props => props.theme.font.size.base};
			line-height: 1.5;
			color: ${props => props.theme.colors.secondary};
			font-weight: 400;
			margin: 50px 0 45px;
			font-family: ${props => props.theme.font.family.bold};

			button {
				cursor: pointer;
				background-color: transparent;
				border: 0;
				padding: 0;
				color: ${props => props.theme.colors.secondary};

				&.active {
					text-decoration: underline;
					color: ${props => props.theme.colors.secondary};
				}
			}
		}
	}
	article {
		margin-bottom: 40px;
  		p.date {
		  font-size: ${props => props.theme.font.size.sm};/*12*/
		  line-height: 1.5;
		  color: ${props => props.theme.colors.secondary};
		  margin: 26px 0 10px;
  		}
  		h2 {
  			font-size: 1.25rem;/*20*/
  			line-height: 1.5;
  			color: ${props => props.theme.colors.primary};
  			font-weight: 400;
			margin: 0 0 10px;
			a {
				color: ${props => props.theme.colors.primary};
			}
		  	a:hover {
				text-decoration: underline;
				color: ${props => props.theme.colors.primary};
		  	}
  		}
  		p.more {
  			text-transform: uppercase;
		    line-height: 1;
		    margin: 0;
		    a {
				font-family: ${props => props.theme.font.family.bold};
				font-size: ${props => props.theme.font.size.sm};
				color: ${props => props.theme.colors.secondary};
				text-decoration: underline;
		    }
  		}
	}
	footer {
	    text-align: center;
	    margin-top: 10px;
	    button {
	    	appearance: none;
	    	background: #fff;
	    	border: solid 1px #323741;
	    	height: 50px;
			line-height: 50px;
			padding: 0 40px;
			font-size: 0.875rem;/*14*/
		    font-family: ${props => props.theme.font.family.bold};
  			color: #323741;
  			text-transform: uppercase;
	    }
	}
`

const postsPerPage = 24;
class News extends Component {	

  /**
   * Constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      paginationLimit: postsPerPage,
      filter: 'all'
    };
  }
  
  /**
   * Load the next page of articles
   */
  loadMore = () => {
	  
      this.setState({
    	  paginationLimit: (this.state.paginationLimit+postsPerPage)
      });
  }
  
  /**
   * set the filter
   */
  setFilter = (filter) => {
	  
      this.setState({
    	  filter: filter
      });
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
			{
				allWpPost(sort: {order: DESC, fields: date}, limit: 1000, filter: {status: {eq: "publish"}}) {
					edges {
						node {
							databaseId
							title
							date
							acfNews {
								newsYoutube
							}
						}
					}
				}
			}
        `}
        render={data => {
			const posts = data.allWpPost.edges.filter(({ node }) => node.title !== "Dummy ACF Content");
			var printedArticles = 0;
		  
          	return (
				<Container>
					<NewsArticle>
						<header>
							<h1>
							<button className={this.state.filter==='all' ? 'active' : ''} onClick={() => this.setFilter('all')}>All</button> /&nbsp;
							<button className={this.state.filter==='news' ? 'active' : ''} onClick={() => this.setFilter('news')}>News</button> /&nbsp; 
							<button className={this.state.filter==='video' ? 'active' : ''} onClick={() => this.setFilter('video')}>Video</button></h1>
						</header>
					
						<Row>
					
							{posts && posts.map(({node}, i) => {
								var articleType = 'news';
								if (node.acfNews.newsYoutube) {
									articleType = 'video';
								}
								if (this.state.paginationLimit>printedArticles && (this.state.filter==='all' || this.state.filter===articleType) ) {
									printedArticles++;
									return (
										<Col md="3" key={i} className={`${articleType} pb-3 pb-md-0`}>
											<NewsPreview id={node.databaseId} />
										</Col>
									)
								}
								return null;
							})}

						</Row>
					
						<footer>
							{/*do we want to display the 'view more' button?*/}
							{
							this.state.paginationLimit<posts.length
							? <button type="button" onClick={() => this.loadMore()}>View More</button>
							: null
							}
						</footer>
					</NewsArticle>
				</Container> 
				  
          	);
        }}
      />
    );
  }
}
export default News;