import React, { Component } from "react"
import LazyLoad from "react-lazy-load"
import HeroCarousel from "./Blocks/HeroCarousel/HeroCarousel"
import TextFullWidth from "./Blocks/TextFullWidth"
import ImageText from "./Blocks/ImageText"
import LinkBlocks from "./Blocks/LinkBlocks/LinkBlocks"
import PlayerProfiles from "./Blocks/PlayerProfiles"
import PlayerProfileCarousel from "./Blocks/PlayerProfileCarousel"
import Testimonials from "./Blocks/Testimonials"
import SocialImages from "./Blocks/SocialImages"
import News from "./Blocks/News"
import NewsletterSignup from "./Blocks/NewsletterSignup"
import NewsCarousel from "./Blocks/NewsCarousel"
import Tickets from "./Blocks/Tickets"
import Partners from "./Blocks/Partners"
import Operators from "./Blocks/Operators"
import FAQs from "./Blocks/FAQs"
import TicketPhasing from "./Blocks/TicketPhasing/TicketPhasing"
import ColumnedImageText from "./Blocks/ColumnedImageText"
import HorizontalImageText from "./Blocks/HorizontalImageText"
import GalleryMasonry from "./Blocks/GalleryMasonry"
import Galleries from "./Blocks/Galleries"
import BellyBandReusable from "./Blocks/BellyBandReusable"
import Schedule from "./Blocks/Schedule"
import PastChampions from "./Blocks/PastChampions"

class FlexibleContent extends Component {

    render() {
        const acfBlocks = this.props.blocks;
        let blocks = ""
        const lazyOffset = 400

        if (acfBlocks !== null) {
            blocks = acfBlocks.map((acf, i) => {

                console.log(acf.__typename)

                switch (acf.__typename) {
                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_HeroCarousel':
                        return <HeroCarousel key={i} block={acf} />

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_TextFullWidth':
                        return <TextFullWidth key={i} block={acf} />

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_ImageWithText':
                        return (
                            <LazyLoad offset={lazyOffset} key={i}>
                                <ImageText block={acf} />
                            </LazyLoad>
                        )

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_LinkBlocks':
                        return (
                            <LazyLoad offset={lazyOffset} key={i}>
                                <LinkBlocks block={acf} />
                            </LazyLoad>
                        )

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_PlayerProfiles':
                        return (
                            <LazyLoad offset={lazyOffset} key={i}>
                                <PlayerProfiles block={acf} />
                            </LazyLoad>
                        ) 
                        
                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_PlayerProfileCarousel':
                        return <PlayerProfileCarousel key={i} block={acf} />

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Testimonials':
                        return (
                            <LazyLoad offset={lazyOffset} key={i}>
                                <Testimonials block={acf} />
                            </LazyLoad>
                        )

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_SocialImages':
                        return (
                            <LazyLoad offset={lazyOffset} key={i}>
                                <SocialImages block={acf} />
                            </LazyLoad>
                        )

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_News':
                        return <News key={i} />

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_NewsCarousel':
                        return (
                            <LazyLoad offset={lazyOffset} key={i}>
                                <NewsCarousel key={i} block={acf} />
                            </LazyLoad>
                        )

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Tickets':
                        return <Tickets key={i} block={acf} />

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_TicketPhasing':
                        return <TicketPhasing key={i} block={acf} />

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Partners':
                        return (
                            <LazyLoad offset={lazyOffset} key={i}>
                                <Partners key={i} block={acf} />
                            </LazyLoad>
                        )

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Operators':
                        return (
                            <LazyLoad offset={lazyOffset} key={i}>
                                <Operators key={i} block={acf} />
                            </LazyLoad>
                        )

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Faqs':
                        return <FAQs key={i} block={acf} />

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_NewsletterSignup':
                        return <NewsletterSignup key={i} />

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_ColumnedImageWithText':
                        return (
                            <LazyLoad offset={lazyOffset} key={i}>
                                <ColumnedImageText block={acf} />
                            </LazyLoad>
                        )

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_HorizontalImageWithText':
                        return (
                            <LazyLoad offset={lazyOffset} key={i}>
                                <HorizontalImageText block={acf} />
                            </LazyLoad>
                        )
                    
                    // cant lazy load these galleries because of masonry package
                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_GalleryMasonry':
                        return <GalleryMasonry key={i} block={acf} />

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Galleries':
                        return <Galleries key={i} block={acf} />

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_GoogleAdBellyband':
                        return <BellyBandReusable key={i} counter={i} block={acf} />

                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Schedule':
                        return <Schedule key={i} block={acf} />
                        
                    case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_PastChampions':
                        return <PastChampions key={i} block={acf} />

                    default:
                        return "";
                }
            })
        }

        return blocks
    }
}

export default FlexibleContent