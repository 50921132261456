import React from "react"
import { Container, Row, Col } from "reactstrap"
import classNames from "classnames"
import { LinkSearchReplace } from "utils/LinkSearchReplace"

const TextBlockFullWidth = (props) => {

    let text = props.block.text

    if (text) {
        // Wrap iframes with responsive div
        text = text.replace('<iframe', '<div class="embed-responsive embed-responsive-16by9"><iframe')
        text = text.replace('</iframe>', '</iframe></div>')

        return (
            <Container>
                <Row className={classNames({
                    "justify-content-center": true,
                    "py-4 py-md-5" : props.block.padding === "yes"
                })}>
                    <Col
                        xs={12}
                        md={props.block.width === "narrow" ? 10 : 12}
                        lg={props.block.width === "narrow" ? 8 : 12}
                        xl={props.block.width === "narrow" ? 6 : 12}
                    >
                        <div dangerouslySetInnerHTML={{ __html: LinkSearchReplace(text) }} />
                    </Col>
                </Row>
            </Container>
        )
    } else {
        return ""
    }
}

export default TextBlockFullWidth