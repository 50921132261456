import React from "react"
import { StaticQuery, graphql } from "gatsby";
import ContainerMax from "components/shared/ContainerMax";
import styled from "styled-components";
import Slider from "react-slick";
import { GatsbyImage } from "gatsby-plugin-image";
import quoteLeft from "images/quote-left.svg";
import quoteRight from "images/quote-right.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { media } from "utils/Media";

const Testimonials = styled.div`
	margin: 5rem 0;
	text-align: center;
	h4 {
		text-transform: uppercase;
	}
	.slick-slider {
		padding-bottom: 66px;
		.slick-slide {
			.text {
				position: relative;
				p {
					max-width: calc(100% - 80px);
					margin: 0 auto 1em;
				    font-size: 1.125rem;/*18*/
				    line-height: 1.44;
				    color: #323741;	
				    @media ${media.md} {
						max-width: 528px;
					    font-size: 1.5rem;/*24*/
					    line-height: 1.33;
				    }
				    @media ${media.lg} {
						max-width: 793px;
				    }
				}
				&:before,
				&:after {
					content: "";
					width: 36px;
					height: 30px;
					background-repeat: no-repeat;
					background-position: 0 0;
					background-size: cover;
					position: absolute;
				}
				&:before {
					background-image: url("${quoteLeft}");
					top: 0;
					left: 0;
				    @media ${media.md} {
						left: 15px;
				    }
				    @media ${media.lg} {
						left: 50%;
						margin-left: -496px;						
				    }
				}
				&:after {
					background-image: url("${quoteRight}");
					bottom: 0;
					right: 0;
				    @media ${media.md} {
						right: 15px;
				    }
				    @media ${media.lg} {
				    	right: auto;
						left: 50%;
						margin-left: 460px;
				    }
				}
			}
			.img-wrap {
				max-width: 126px;
				margin: 47px auto 0;
			    @media ${media.md} {
			    	max-width: 182px;
			    }
			}
		}
		.solheim-arrow {
			position: absolute;
			bottom: -14px;
			background: transparent;
			appearance: none;
			border: none;
			font-size:24px;
			z-index: 10;
			color: ${props => props.theme.colors.secondary};
			
		    @media ${media.lg} {
		    	bottom: auto;
		    	top: 50%;
				transform: translateY(-50%);
		    }
			&.prev {
				left: 0;
			    @media ${media.md} {
					left: 50%;
					margin-left: -140px;
			    }
			    @media ${media.lg} {
			    	left: 0;
			    	margin: 0;
			    }
			}
			&.next {
				right: 0;
			    @media ${media.md} {
					right: 50%;
					margin-right: -140px;
			    }
			    @media ${media.lg} {
			    	right: 0;
			    	margin: 0;
			    }
			}
			&:hover {
				color: ${props => props.theme.colors.secondary};
			}
			svg {
				max-width: 12px;
				max-height: 24px;
			}
		}
		.slick-dots {
			height: 10px;
			line-height: 10px;
			bottom: 0;
			&:before,
			&:after {
				content: '';  
				width: 6px;
				height: 6px;
				background-color: ${props => props.theme.colors.primary};
				border-radius: 50%;
				display: inline-block;
				vertical-align: middle;
				margin: 0 2.5px;
			}
			li {
				margin: 0 2.5px;
				width: auto;
				height: auto;
				vertical-align: middle;
				button {
					width: 10px;
					height: 10px;
					background-color: ${props => props.theme.colors.primary};
					border-radius: 50%;
					&:before {
					  display: none;
					}
				}
				&.slick-active button {
					background: ${props => props.theme.colors.secondary};
				}
			}
		}
	}
`
export default ({ block }) => (
    <StaticQuery
        query={graphql`{
  allWp {
    edges {
      node {
        options {
          acfOptions {
            blockTestimonials {
              defaultSubtitle
              defaultTitle
              testimonials {
                testimonial
                logo {
                  altText
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 300, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`}
        render={data => {

        	function PrevButton({ onClick }) {
        	      return <button onClick={onClick} className="solheim-arrow prev"><FontAwesomeIcon icon={faChevronLeft} /></button>;
        	}

    	    function NextButton({ onClick }) {
    	      return <button onClick={onClick} className="solheim-arrow next"><FontAwesomeIcon icon={faChevronRight} /></button>;
    	    }

        	//if we're overriding the defaults, the data comes form the block, otherwise it comes from the options page
        	let title = data.allWp.edges[0].node.options.acfOptions.blockTestimonials.defaultTitle;
        	let subtitle = data.allWp.edges[0].node.options.acfOptions.blockTestimonials.defaultSubtitle;
        	let testimonials = data.allWp.edges[0].node.options.acfOptions.blockTestimonials.testimonials;
        	
        	if (block.overrideDefaults) {
        		title = block.title;
        		subtitle = block.subtitle;
        		testimonials = 	block.testimonials;
        	}
        	
        	//slider settings
            var settings = {
      	        dots: true,
      	        infinite: true,
				speed: 500,
				focusable: false,  
      	        slidesToShow: 1,
				slidesToScroll: 1,
				lazyload: true,
      	        prevArrow: <PrevButton />,
      	        nextArrow: <NextButton />,
			}
        	
            return (
                <ContainerMax>
                	<Testimonials>
        	        	<h4 dangerouslySetInnerHTML={{ __html: title }} />            
        	        	<h5 dangerouslySetInnerHTML={{ __html: subtitle }} />
	            	      <Slider {...settings}>
		            	      {testimonials && testimonials.map((testimonial, i) => (
		            	    	  	<div className="testimonial" key={i}>
		          	        			<div className="text" dangerouslySetInnerHTML={{ __html: testimonial.testimonial }} />
										{testimonial.logo &&
											<div className="img-wrap">
												<GatsbyImage
                                                    image={testimonial.logo.localFile.childImageSharp.gatsbyImageData}
                                                    alt={testimonial.logo.altText} />
											</div>
										}
		            	    	  </div>
		  		              ))}
	            	      </Slider>
                	</Testimonials>
                </ContainerMax>
            );
        }}
    />
)