import React, { useEffect, useRef, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "reactstrap" 
import styled, { css } from "styled-components"
import { media } from "utils/Media"

const Wrap = styled.div`
  position: relative;


  ${props => props.$active && css`
    .overlay {
      background: black;
      opacity: .5;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 6;
    }
  `}

`

const PastChampContainer = styled(Container)`
  position: relative;

  .row {
    position: relative;
  }

  .column {
    position: unset;
  }

  h2 {
    text-transform: uppercase;
  }
`

const ChampThumbWrap = styled.div`
  position: relative;
  cursor: pointer;

  &:after {
    content: "";
    z-index: 4;
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 79.69%);
  }

  .img {
    position: relative;
    z-index: 3;
    /* max-width: 100%; */
  }

  .content {
    position: absolute;
    z-index: 5;
    bottom: 1rem;
    text-align: center;
    width: 100%;
    padding: 1rem;

    h3, h4 {
      color: white;
      text-transform: uppercase;
    }

    h3 {
      font-size: 30px;
    }

    h4 {
      font-size: 16px;
    }
  }

  .icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: .75rem;
    width: 15px;
    height: 15px;
    z-index: 5;

    ${props => props.$large && css`
      bottom: 1.5rem;
      width: 25px;
      height: 25px;
    `}

    &:before, &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      top: 50%;
      left: 50%;
      background: ${props => props.theme.colors.secondary};
      transform: translate(-50%, -50%);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(90deg);

      ${props => props.$active && css`
        display: none;
      `}
    }
  }

  ${props => props.$large && css`
    .content {
      padding: 1rem;
      padding-bottom: 3rem;
      h3 {
        font-size: 50px;
        padding-bottom: 1.5rem;
      }
      h4 {
        font-size: 30px;
      }
    }
  `}

  &:before {
    content: "";
    background-color: ${props => props.theme.colors.secondary};
    position: absolute;
    z-index: 1;
    left: -5px;
    top: -5px;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    display: none;
  }

  &:hover {
    &:before {
      display: block;
    }
  }

  ${props => props.$active && css`
    z-index: 10;
    &:before {
      display: block;
    }
  `}

  ${props => props.marginBottom && css`
    margin-bottom: ${props => props.marginBottom}px;
  `}
`

const ChampDescriptionWrap = styled.div`
  position: absolute;
  width: calc(100% - 30px);
  color: white;
  text-align: center;
  left: 15px;
  padding-top: 1rem;

  opacity: 0;
  visibility: hidden;

  .inner {
    background-color: ${props => props.theme.colors.secondary}; 
    border-radius: 20px;
    padding: 1rem;

    @media ${media.md} {
      padding: 2rem;
    }

    h3, h4 {
     color: white;
     font-size: 40px;

     @media ${media.md} {
      font-size: 50px;
     }
    }
    h4 {
     color: white;
     font-size: 20px;
     @media ${media.md} {
      font-size: 30px;
     }
    }
  }

  .close {
    background: transparent;
    border: 0;
    position: absolute;
    right: 1rem;
    top: 2rem;
  }

  ${props => props.$active && css`
    z-index: 10;
    opacity: 1;
    visibility: visible;
  `}
`

export default function PastChampions({block}) {
  const [activeItem, setActiveItem] = useState(null)
  const [marginBottom, setMarginBottom] = useState(0)

  const {
    title,
    champions
  } = block

  const triggerClick = (e, i) => {
    // e.currentTarget.classList.toggle('active')
    setActiveItem(activeItem === i ? null : i)

    if (activeItem !== i) {
      const node = e.currentTarget.nextSibling
      const navHeight = document.getElementById('siteHeader').offsetHeight;

      if (node) {
        setTimeout(() => {
          const y = node.getBoundingClientRect().top + window.pageYOffset - navHeight - 10;
          window.scrollTo({top: y, behavior: 'smooth'});
        }, 300);

      } 
    }
  }

  return(
    <Wrap $active={activeItem !== null}>
      <div className="overlay" onClick={() => setActiveItem(null)}></div>
      <PastChampContainer className="py-4">
        {title && (
          <h2>{title}</h2>
        )}
        <Row>
          {champions?.map((champion, i) => {
            const { 
              year,
              name,
              fullWidth,
              landscapeImage,
              portraitImage,
              mobileImage,
              description
            } = champion

            if (fullWidth) {
              return(
                <Col xs={12} key={`pc${i}`} className="column pb-4">
                  <ChampThumb 
                    $large={true} 
                    onClick={(e) => triggerClick(e, i)} 
                    $active={activeItem === i}
                    year={year}
                    name={name}
                    image={landscapeImage}
                    mobileImage={mobileImage}
                  />
                  <ChampDescription 
                    year={year}
                    name={name}
                    description={description}
                    $active={activeItem === i}
                    setMarginBottom={setMarginBottom}
                    closeDesc={(e) => triggerClick(e, i)} 
                  />
                  <DescFiller $active={activeItem === i} marginBottom={marginBottom} />
                </Col>
              )
            } else {
              return(
                <Col xs={12} sm={6} md={4} lg={3} key={`pc${i}`} className="column pb-4">
                  <ChampThumb 
                    onClick={(e) => triggerClick(e, i)} 
                    $active={activeItem === i}
                    year={year}
                    name={name}
                    image={portraitImage}
                    mobileImage={mobileImage}
                  />
                  <ChampDescription 
                    year={year}
                    name={name}
                    description={description}
                    $active={activeItem === i}
                    setMarginBottom={setMarginBottom}
                    closeDesc={(e) => triggerClick(e, i)}
                  />
                  <DescFiller $active={activeItem === i} marginBottom={marginBottom} />
                </Col>
              )
            }
          })}
        </Row>
      </PastChampContainer>
    </Wrap>
  )
}

const ChampThumb = ({
  $large=false,
  onClick, 
  $active,
  year,
  name,
  image,
  mobileImage
}) => {
  return(
    <ChampThumbWrap $large={$large} onClick={onClick} $active={$active}>
      <GatsbyImage
        image={image.localFile.childImageSharp.gatsbyImageData}
        alt={image.altText}
        className="img d-none d-sm-block"
      />
      <GatsbyImage
        image={mobileImage.localFile.childImageSharp.gatsbyImageData}
        alt={mobileImage.altText}
        className="img d-sm-none"
      />
      <div className="content">
        <h3>{year}</h3>
        <h4>{name}</h4>
      </div>
      <span className="icon"></span>
    </ChampThumbWrap>
  )
}

const ChampDescription = ({year, name, description, $active, setMarginBottom, closeDesc}) => {
  const ref = useRef()

  useEffect(() => {
    window.addEventListener('resize', () => setMargin($active))

    return () => {
      window.removeEventListener('resize', () => setMargin($active))
    }
  }, [])

  useEffect(() => {
    setMargin($active)
  }, [$active])

  const setMargin = ($active) => {
    if ($active) {
      setMarginBottom(ref.current.clientHeight)
    }
  }

  return(
    <ChampDescriptionWrap $active={$active} ref={ref}>
      <div className="inner">
        <button className="close" onClick={() => closeDesc(null)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <path d="M2 24L24 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
            <path d="M2 2L24 24" stroke="white" strokeWidth="3" strokeLinecap="round"/>
          </svg>
        </button>
        <h3>{year}</h3>
        <h4>{name}</h4>
        <div dangerouslySetInnerHTML={{ __html: description}} />
      </div>
    </ChampDescriptionWrap>
  )
}

const DescFiller = ({$active, marginBottom}) => {
  const [mb, setMb] = useState(marginBottom)

  useEffect(() => {
    if ($active) {
      setMb(marginBottom)
    } else {
      setMb(0)
    }
  }, [$active, marginBottom])

  return(
    <div style={{
      marginBottom: mb + 'px'
    }}/>
  )
}