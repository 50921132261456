import React from "react"
import { StaticQuery, graphql } from "gatsby"
//import ContainerMax from "components/shared/ContainerMax";
import { Container } from "reactstrap"
import styled from "styled-components"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons"
import { media } from "utils/Media"
import NewsPreview from "components/shared/NewsPreview"
import WPLink from "components/shared/WPLink"

const NewsCarousel = styled.div`
	margin-bottom: 40px;
	margin-right: -9px;		
	padding-top: 50px;

    @media ${media.md} {
		margin-right: 0;
		margin-bottom: 50px;
    }		

    @media ${media.lg} {
		margin-bottom: 60px;
    }	

	h3 {
		font-size: 1.125rem;/*18*/
		font-family: ${props => props.theme.font.family.base};
		color: ${props => props.theme.colors.primary};
		line-height: 1.44;
		margin: 0 0 1.5rem;
		font-weight: 400;		

	    @media ${media.md} {
			font-size: 1.5rem;/*24*/
			line-height: 1.5;
			margin: 0 0;
	    }	

	    @media ${media.lg} {
			margin: 0 0 17px;	
	    }	 
	}
	
	.slick-slider {
		padding-bottom: 30px;
	    @media ${media.md} {
			padding-bottom: 42px;
	    }			
	    @media ${media.lg} {
    		padding-bottom: 22px;
	    }	 
		.slick-list {
			margin: 0 -6px;
		    @media ${media.md} {
				margin: 0 -12px;	
		    }		
		    @media ${media.lg} {
	    		padding: 20px 0;
		    }	 
		}
		.slick-track {
			display: flex;
		}
		.slick-slide {
			padding: 0 6px;	
	        height: auto;
		    @media ${media.md} {
				padding: 0 12px;
		    }
		    > div {
		    	height: 100%;
		    }
		    article {
		    	height: 100%;
		    	background: ${props => props.theme.colors.primary};
		    	padding-bottom: 20px;
		    	margin-bottom: 0;		
				width: 100%;
				display: block;
				position: relative;

			    @media ${media.lg} {
		    		transition: transform .2s;
					transform: scale(1);
					&:hover {
						transform: scale(1.06);
					}
			    }	
		    	.gatsby-image-wrapper {
		    		height: 216px;
		    	}
		    	p.date {
		    		color: ${props => props.theme.colors.white};
		    		padding: 0 12px;
		    		margin: 12px 0 16px;		
				    @media ${media.md} {
		    			margin: 24px 0 18px;
		    			padding: 0 25px;
				    }	
		    	}
		    	h2 {
					font-size: 1.125rem;/*18*/
		    		line-height: 1.44;
		    		padding: 0 12px;
					margin: 16px 0 15px;
					color: white;	
				    @media ${media.md} {
		    			margin: 18px 0 24px;
						font-size: 1.25rem;/*20*/
		    			padding: 0 25px;
				    }	
		    		a {
		    			color: white;
		    			&:hover {
		    				color: white;
		    			}
		    		}
		    	}
		    	p.more {  
		    		font-size: 0.875rem;/*14*/  
					padding: 0 12px;
					text-decoration: underline;
				    @media ${media.md} {
		    			padding: 0 25px;
				    }	
		    		a {
		    			color: ${props => props.theme.colors.white};
		    		}
		    	}
		    }
		}
		.solheim-arrow {
			position: absolute;
			bottom: -14px;
			background: transparent;
			appearance: none;
			border: none;
			font-size:24px;
			z-index: 10;
			padding: 0;
			color: ${props => props.theme.colors.secondary};
		    @media ${media.md} {
		    	bottom: auto;
		    	top: 50%;
				transform: translateY(-50%);
		    }
			&.prev {
				left: 0;
			    @media ${media.md} {
					left: -20px;
			    }
			    @media ${media.lg} {
			    	left: -70px;
			    }
			}
			&.next {
				right: 0;
			    @media ${media.md} {
					right: -20px;
			    }
			    @media ${media.lg} {
			    	right: -70px;
			    }
			}

			svg {
				max-width: 12px;
				max-height: 24px;
			}


			&:hover {
				color: ${props => props.theme.colors.secondary};
			}
		}
		.slick-dots {
			height: 10px;
			line-height: 10px;
			bottom: 0;
			&:before,
			&:after {
				content: '';  
				width: 6px;
				height: 6px;
				background-color: ${props => props.theme.colors.primary};
				border-radius: 50%;
				display: inline-block;
				vertical-align: middle;
				margin: 0 2.5px;
			}
			li {
				margin: 0 2.5px;
				width: auto;
				height: auto;
				vertical-align: middle;
				button {
					width: 10px;
					height: 10px;
					background: ${props => props.theme.colors.primary};
					border-radius: 50%;
					&:before {
					  display: none;
					}
				}
				&.slick-active button {
					background: ${props => props.theme.colors.secondary};
				}
			}
		}
	}
	p.view-all {
		text-align: center;
	    margin: 20px 0 0;
	    @media ${media.md} {
			margin: 25px 0 0;
	    }
		a {
			display: inline-block;
	    	background: #fff;
	    	border: solid 1px #323741;
	    	height: 50px;
			line-height: 50px;
			padding: 0 40px;
			font-size: 0.875rem;/*14*/
		    font-family: ${props => props.theme.font.family.bold};
  			color: #323741;
  			text-transform: uppercase;
		}
	}
`
export default ({ block }) => (
    <StaticQuery
    query={graphql`
			{
				allWpPost(sort: {order: DESC, fields: date}, limit: 12, filter: {status: {eq: "publish"}}) {
					edges {
						node {
							databaseId
							title
						}
					}
				}
			}
        `}
        render={data => {

			const posts = data.allWpPost.edges

			const getNews = posts && posts.map(({node}, i) => {
                return <NewsPreview id={node.databaseId} key={i} lazyload={false} />
            })
        	
        	function PrevButton({ onClick }) {
        	      return <button onClick={onClick} className="solheim-arrow prev"><FontAwesomeIcon icon={faChevronLeft} /></button>;
        	}

    	    function NextButton({ onClick }) {
    	      return <button onClick={onClick} className="solheim-arrow next"><FontAwesomeIcon icon={faChevronRight} /></button>;
    	    }
        	
            //slider settings
            var settingsXl = {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 3,
                lazyload: true,
                prevArrow: <PrevButton/>,
                nextArrow: <NextButton/>
            };

            var settingsMd = {
                dots: true,
                infinite: false,
                speed: 500,
                slidesToShow: 2,
                slidesToScroll: 2,
                lazyload: true,
                prevArrow: <PrevButton/>,
                nextArrow: <NextButton/>
            };

            var settingsSm = {
                dots: true,
                infinite: false,
                speed: 500,
                slidesToShow: 1.22,
                slidesToScroll: 1,
                lazyload: true,
                prevArrow: <PrevButton/>,
                nextArrow: <NextButton/>
            };
        	
            return (
                <Container>
                	<NewsCarousel>
        	        	<h3 dangerouslySetInnerHTML={{ __html: block.title }} />    
        		            	   
                        <div className="d-none d-xl-block">
                            <Slider {...settingsXl}>
                                {getNews}
                            </Slider>
                        </div>

                        <div className="d-none d-md-block d-xl-none">
                            <Slider {...settingsMd}>
                                {getNews}
                            </Slider>
                        </div>

                        <div className="d-block d-md-none">
                            <Slider {...settingsSm}>
                                {getNews}
                            </Slider>
                        </div>
	        			
	        			{/*do we have a view all link?*/}
	        			{block.button &&
	        				<div className="text-center pt-4">
		        				<WPLink url={block.button.url} target={block.button.target} button color="transparentPrimary">
		                            {block.button.title}
		                        </WPLink>
	                    	</div>
	                    }
        		            	  
                	</NewsCarousel>
                </Container>
            )
        }}
    />
)