import React from "react"
import { Container, Row, Col } from "reactstrap"
import { GatsbyImage } from "gatsby-plugin-image";

const Operators = (props) => {
    return (
        <Container className="py-4">
            <Row className="justify-content-center">
                {props.block.operators.map((operator, i) => {
                    return (
                        <React.Fragment key={i}>
                            <Col xs={12} sm={6} md={4} lg={3} className="pb-4">
                                <div style={{ position: "relative" }}>
                                    {operator.url !== null ? (
                                        <a href={operator.url} target="_blank" rel="noopener noreferrer">
                                            <GatsbyImage
                                                image={operator.image.localFile.childImageSharp.gatsbyImageData}
                                                style={{ maxWidth: "300px", margin: "0 auto" }}
                                                alt={operator.image.altText} />
                                        </a>
                                    ) : (
                                        <GatsbyImage
                                            image={operator.image.localFile.childImageSharp.gatsbyImageData}
                                            style={{ maxWidth: "300px", margin: "0 auto" }}
                                            alt={operator.image.altText} />
                                    )}
                                </div>
                                {operator.text !== null &&
                                    <div dangerouslySetInnerHTML={{ __html: operator.text }} className="pt-3 text-center" />
                                }
                            </Col>
                            {operator.lineBreak === "yes" &&
                                <div className="w-100"></div>
                            }
                        </React.Fragment>
                    );
                })}
            </Row>
        </Container>
    );
}

export default Operators