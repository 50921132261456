import React from "react"
import styled, { css } from "styled-components"
import { media } from "utils/Media"

const TicketHeadingStyled = styled.th`
	position: relative;
	padding: 1rem 0 2rem !important;
	text-align: center;

	.heading {
		width: 100%;
		padding-bottom: .5rem;
		color: ${props => props.theme.colors.secondary};
        font-size: ${props => props.theme.font.size.xs};

        @media ${media.md} {
            font-size: ${props => props.theme.font.size.base};
        }

        ${props => props.status === "no" && css`
            color: ${props => props.theme.colors.grey};
            opacity: .8;
        `}
	}

    .headingWrap {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;

        &:before,
        &:after {
            content: "";
            width: 50%;
            height: 1px;
            background-color: ${props => props.theme.colors.grey4};
            position: absolute;
            left: 0;
            bottom: 5px;
        }

        &:after {
            left: auto;
            right: 0;
        }

        ${props => props.first && css`
            &:before {
                display: none;
            }
        `}

        ${props => props.last && css`
            &:after {
                display: none;
            }
        `}
    }

	.circle {
		height: 10px;
		width: 10px;
		border-radius: 50%;
		background-color: ${props => props.theme.colors.secondary};
		position: relative;
		z-index: 2;

        ${props => props.status === "no" && css`
            background-color: ${props => props.theme.colors.grey};
            opacity: .8;
        `}
	}

	p {
		width: 100%;
	}
`

const TicketHeading = (props) => {
    const phase = props.phase
    return(
        <TicketHeadingStyled {...props}>
            <div className="headingWrap">
                <div className="heading">{phase.name}</div>
                <div className="circle" />
            </div>
        </TicketHeadingStyled>
    )
}

export default TicketHeading