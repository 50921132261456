/**
 * Preview of a news article
 * Used on news index and homepage news carosuel
 */

 import React from "react";
 import { Link, graphql, StaticQuery } from "gatsby";
 import styled, { css } from "styled-components";
 import { GatsbyImage } from "gatsby-plugin-image";
 import YoutubeVideoOverlay from "components/shared/YoutubeVideoOverlay";
 import ButtonPlay from "components/shared/ButtonPlay";
 import DateFormat from "components/shared/DateFormat";
 
 const Heading = styled.h2`
   line-height: 1.15 !important;
 
   ${(props) =>
     props.carousel &&
     css`
       color: white;
     `}
 `;
 
 export default ({ id, carousel, lazyload }) => (
   <StaticQuery
     query={graphql`
       {
         allWpPost(sort: { order: DESC, fields: date }, limit: 1000) {
           edges {
             node {
               databaseId
               slug
               title
               date
               featuredImage {
                 node {
                   altText
                   sourceUrl
                   localFile {
                     childImageSharp {
                       gatsbyImageData(
                         width: 768
                         quality: 90
                         placeholder: NONE
                         layout: CONSTRAINED
                       )
                     }
                   }
                 }
               }
               acfNews {
                 newsYoutube
                 newsYoutubeDuration
               }
             }
           }
         }
       }
     `}
     render={(data) => {
       return (
         // loop blocks and print if id matches
         data.allWpPost.edges.map(({ node }, i) => {
           if (node.databaseId === id) {
             return (
               <article key={i}>
                 {/*if we have a youtube video, we print that, otherwise just print the featured image, if there is one*/}
                 {(() => {
                   if (node.acfNews.newsYoutube) {
                     return (
                       <YoutubeVideoOverlay oembed={node.acfNews.newsYoutube}>
                         <ButtonPlay />
                         {node.featuredImage.node.localFile && (
                           <GatsbyImage
                             image={
                               node.featuredImage.node.localFile.childImageSharp
                                 .gatsbyImageData
                             }
                             alt={node.featuredImage.altText}
                           />
                         )}
                         {node.acfNews.newsYoutubeDuration1 ? (
                           <span className="duration">
                             {node.acfNews.newsYoutubeDuration1}
                           </span>
                         ) : null}
                       </YoutubeVideoOverlay>
                     );
                   } else if (node.featuredImage) {
                     return (
                       <Link to={`/news/${node.slug}/`}>
                         <GatsbyImage
                           image={
                             node.featuredImage.node.localFile.childImageSharp
                               .gatsbyImageData
                           }
                           alt={node.featuredImage.altText}
                         />
                       </Link>
                     );
                   }
                   return null;
                 })()}
                 <p className="date">
                   <DateFormat date={node.date} />
                 </p>
                 {!node.acfNews.newsYoutube ? (
                   <>
                     <Heading>
                       <Link
                         to={`/news/${node.slug}/`}
                         dangerouslySetInnerHTML={{ __html: node.title }}
                       ></Link>
                     </Heading>
                     <p className="more">
                       <Link to={`/news/${node.slug}/`}>Read More</Link>
                     </p>
                   </>
                 ) : (
                   <>
                     <Heading carousel={carousel}>{node.title}</Heading>
                   </>
                 )}
               </article>
             );
           }
           return "";
         })
       );
     }}
   />
 );
 