import React, { Component } from 'react'
import { StaticQuery, graphql } from "gatsby"
import { DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import classNames from "classnames"
import Slider from "react-slick"
import PlayerProfile from "components/shared/PlayerProfile"
import FilterDropdown from "components/shared/FilterDropdown"
import BlockTitle from "components/shared/BlockTitle"

const Team = styled.div`
	/* margin-bottom: 150px; */
	h2 {
		font-family: ${props => props.theme.font.family.bold};
		font-size: 1.5rem;
		line-height: 1.5;
		color: #1D2E41;
		margin: 1rem 0;
	}
`

const SliderStyled = styled(Slider)`
    .slick-arrow {
        bottom: -3rem;
        z-index: 12;
        top: auto;

        &:before {
            color: ${props => props.theme.colors.black};
        }
    }
`

class PlayerBlockFullWidth extends Component {

    constructor(props) {
        super(props)

        this.state = {
            statsOpen: [],
            filter: "",
            filterText: "SELECT (ALL)",
            activeFilter: "",
            device: "mobile"
        }

        this.statsToggled = this.statsToggled.bind(this)
        this.checkWindowSize = this.checkWindowSize.bind(this)
    }

    componentDidMount() {
        this.checkWindowSize()
        window.addEventListener('resize', this.checkWindowSize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkWindowSize)
    }

    checkWindowSize() {
        const windowWidth = typeof window !== "undefined" ? window.innerWidth : ""

        this.setState({
            device: windowWidth < 768 ? "mobile" : "desktop"
        })
    }

    statsToggled(id) {

        let statsOpen = this.state.statsOpen

        if (statsOpen.includes(id)) {
            for (let i = 0; i < statsOpen.length; i++) {
                if (statsOpen[i] === id) {
                    statsOpen.splice(i, 1);
                }
            }
        } else {
            statsOpen.push(id)
        }

        this.setState({ statsOpen })
    }

    setFilter(id, text) {
        this.setState({
            filter: id,
            filterText: text
        })
    }

    chunk(array, size) {
        return array.reduce((chunks, item, i) => {
            if (i % size === 0) {
                chunks.push([item]);
            } else {
                chunks[chunks.length - 1].push(item);
            }
            return chunks;
        }, []);
    }

	render() {
        const categories = this.props.data.playercategories
        const filterType = this.props.data.filterType
        const dropdownInfo = this.props.data.dropdownInfo === "yes" ? true : false
        const filters = this.props[filterType]
        
        let flag
        let chunkedPlayers
        let players = this.props.players

        // Filter for categories
        if (categories) {
            players = players.filter(player => {
                let r = false
                categories.forEach(cat => {
                    player.node.playercategories.nodes.forEach(playerCat => {
                        if (playerCat.node.databaseId === cat.databaseId) {
                            r = player
                        }
                    })
                });
                return r
            })    
        }

        // Filter players by selected filters
        let filteredPlayers = players.filter(player => {
            if (this.state.filter !== "") {
                let show = false
                if (filterType === "decade") {
                    player.node.decades.nodes.forEach(playerDec => {
                        if (playerDec.databaseId === this.state.filter) {
                            show = true
                        }
                    })
                } else if (filterType === "tournament") {
                    player.node.tournaments.nodes.forEach(playerTourn => {
                        if (playerTourn.databaseId === this.state.filter) {
                            show = true
                        }
                    })
                } else if (filterType === "nationality") {
                    player.node.nationalities.nodes.forEach(playerNat => {
                        if (playerNat.databaseId === this.state.filter) {
                            show = true
                        }
                    })
                } else if (filterType === "alphabetically") {
                    player.node.alphabetically.nodes.forEach(alphabet => {
                        if (alphabet.databaseId === this.state.filter) {
                            show = true
                        }
                    })
                }

                return show
            } else return player
        })

        // Split players into groups of 4 for mobile carousel
        if (this.state.device === "mobile") {
            chunkedPlayers = this.chunk(filteredPlayers, 4);
        }

        //slider settings
        var settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            // prevArrow: <PrevButton />,
            // nextArrow: <NextButton />,
        };

        return (
            <Container className={classNames({ 
                "py-5": true,
                statsOpen: this.state.statsOpen.length > 0 
            })}>
                <Team>
                    <Row>
                        <Col>
                            {this.props.data.title && 
                                <BlockTitle base>{this.props.data.title}</BlockTitle>
                            }
                            {filterType !== "none" &&
                                <FilterDropdown>
                                    <DropdownToggle caret>
                                        {this.state.filterText}
                                        <span className="icon icon-down-open"></span>
                                    </DropdownToggle>
                                    
                                    <DropdownMenu>
                                        {this.state.filterText !== "SELECT (ALL)" && 
                                            <DropdownItem 
                                                onClick={() => this.setFilter("", "SELECT (ALL)")}
                                            >
                                                SELECT (ALL)
                                            </DropdownItem>
                                        }
                                        {filters && filters.map((filter, i) => {
                                            if (this.state.filterText !== filter.node.name) {
                                                return (
                                                    <DropdownItem 
                                                        key={i}
                                                        onClick={() => this.setFilter(filter.node.databaseId, filter.node.name)}
                                                    >
                                                        {filter.node.name}
                                                    </DropdownItem>
                                                )
                                            } else return ""
                                        })}
                                    </DropdownMenu>
                                </FilterDropdown>
                            }
                        </Col>
                    </Row>
                    <Row>
                        {this.state.device === "mobile" ? (
                            <Col>
                                <SliderStyled {...settings}>
                                    {/* loop through the player profiles and print out the ones where the team matches*/}
                                    {chunkedPlayers && chunkedPlayers.map((players, i) => {
                                        return (
                                            <div key={i}>
                                                <Row>
                                                    {players && players.map((player, i) => {
                                                        flag = this.props.nationality.filter(({node}) => node.databaseId === player.node.nationalities.nodes[0].databaseId);
                                                        return(
                                                            <Col key={i} xs="6" md="3">
                                                                <PlayerProfile
                                                                    data={player.node}
                                                                    id={i}
                                                                    statsToggled={false}
                                                                    dropdownInfo={false}
                                                                    flag={flag ? flag[0].node.acfPlayerNationalities.flag.sourceUrl : ""}
                                                                />
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </div>
                                        )
                                    })}
                                </SliderStyled>
                            </Col>
                        ) : (
                            /*loop through the player profiles and print out the ones where the team matches*/
                            filteredPlayers && filteredPlayers.map((player, i) => {
                                flag = this.props.nationality.filter(({node}) => node.databaseId === player.node.nationalities.nodes[0].databaseId);

                                return <Col key={i} xs="6" md="3">
                                    <PlayerProfile
                                        data={player.node}
                                        id={i}
                                        statsToggled={() => this.statsToggled(i)}
                                        dropdownInfo={dropdownInfo}
                                        flag={flag ? flag[0].node.acfPlayerNationalities.flag.sourceUrl : ""}
                                    />
                                </Col>
                            })
                        )}
                    </Row>
                </Team>
            </Container>
        )
    }

}

export default (props) => (
    <StaticQuery
        query={graphql`{
            allWpPlayer {
                edges {
                    node {
                        title
                        featuredImage {
                            node {
                                altText
                                sourceUrl
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 300, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                                    }
                                }
                            }
                        }
                        acfPlayers {
                            alternativeName
                            age
                            playerDob
                            bestAtpRating
                            relation
                            noOfTitles
                            pgaWins
                            usOpenWins
                            theOpenWins
                            turnedPro
                            proWins
                            bio
                            facebook
                            twitter
                            instagram
                        }
                        playercategories {
                            nodes {
                                databaseId
                            }
                        }
                        nationalities {
                            nodes {
                                databaseId
                            }
                        }
                        decades {
                            nodes {
                                databaseId
                            }
                        }
                        tournaments {
                            nodes {
                                databaseId
                            }
                        }
                        alphabetically {
                            nodes {
                                databaseId
                            }
                        }
                    }
                }
            }
            allWpNationality {
                edges {
                    node {
                        databaseId
                        name
                        slug
                        acfPlayerNationalities {
                            flag {
                                sourceUrl
                            }
                        }
                    }
                }
            }
            allWpDecade {
                edges {
                    node {
                        databaseId
                        name
                        slug
                    }
                }
            }
            allWpTournament {
                edges {
                    node {
                        databaseId
                        name
                        slug
                    }
                }
            }
            allWpAlphabet {
                edges {
                    node {
                        databaseId
                        name
                        slug
                    }
                }
            }
        }
        `}
        render={data => {
            return (
                <PlayerBlockFullWidth 
                    data={props.block} 
                    players={data.allWpPlayer.edges} 
                    nationality={data.allWpNationality.edges}
                    decade={data.allWpDecade.edges}
                    tournament={data.allWpTournament.edges}
                    alphabetically={data.allWpAlphabet.edges}
                />
            )
        }}
    />
)