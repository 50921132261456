import React, { Component } from "react"
import Bricks from "bricks.js"
import GalleryGridItem from "./GalleryGridItem"
import GalleryLightbox from "./GalleryLightbox"
import Button from "components/shared/Button"

class GalleryGrid extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            paginationLimit: 12,
            filter: 'reload',
            postCount: 0,
            postsHtml: "",
            modalOpen: false,
            filterActive: false,
            activeImage: 0,
            images: this.props.images
        }

        this.instance = null
        this.postsPerPage = 12
        this.sizes = [
            { columns: 2, gutter: 10 },
            { mq: '576px', columns: 2, gutter: 20 },
            { mq: '768px', columns: 3, gutter: 20 },
            { mq: '992px', columns: 4, gutter: 20 },
            { mq: '1200px', columns: 4, gutter: 20 },
        ]

        this.initBricks = this.initBricks.bind(this)
        this.rePackUpdate = this.rePackUpdate.bind(this)
        this.setImages = this.setImages.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.viewMoreImages = this.viewMoreImages.bind(this)
    }

    componentDidMount() {
        this.initBricks(this.props.images)
        window.addEventListener('resize', () => this.setImages)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setImages, true)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.images !== this.props.images) {
            this.setState({
                images: this.props.images
            })
            this.setImages(this.props.images)
        } else {
            this.rePackUpdate()
        }
    }

    rePackUpdate() {
        this.instance.pack()
        this.instance.update()
    }

    initBricks(images=null) {
        // define Bricks options
        const options = {
            container: this.grid,
            packed: 'data-packed',
            sizes: this.sizes
        }

        // create Bricks instance, adding resize handler
        this.instance = Bricks(options).resize(true)

        // bind callbacks
        this.instance
            .on('pack', () => console.log('ALL grid items packed.'))
            .on('update', () => console.log('NEW grid items packed.'))
            .on('resize', size => console.log('The grid has be re-packed to accommodate a new BREAKPOINT.'))

        this.setImages(images)
    }

    calcSize(originalImageHeight) {
        if (this.gridWrap) {
            const windowSize = window.innerWidth
            const gridWidth = this.gridWrap.getBoundingClientRect().width - 30
            let originalImageWidth = 300 // wordpress thumbnail size, 330 based from widest width below
            let width, height, gutterAmount, gutterWidth, columnAmount

            if (windowSize > 1199) {
                gutterAmount = this.sizes[4].columns - 1
                gutterWidth = this.sizes[4].gutter
                columnAmount = this.sizes[4].columns
            } else if (windowSize > 991) {
                gutterAmount = this.sizes[3].columns - 1
                gutterWidth = this.sizes[3].gutter
                columnAmount = this.sizes[3].columns
            } else if (windowSize > 767) {
                gutterAmount = this.sizes[2].columns - 1
                gutterWidth = this.sizes[2].gutter
                columnAmount = this.sizes[2].columns
            } else if (windowSize > 575) {
                gutterAmount = this.sizes[1].columns - 1
                gutterWidth = this.sizes[1].gutter
                columnAmount = this.sizes[1].columns
            } else {
                gutterAmount = this.sizes[0].columns - 1
                gutterWidth = this.sizes[0].gutter
                columnAmount = this.sizes[0].columns
            }

            // width is determined by [ ( container size excluding padding - (number of gutters * gutter width)) / number of columns ]
            width = (gridWidth - (gutterAmount * gutterWidth)) / columnAmount

            // calc new height to keep image ratio
            height = (originalImageHeight / originalImageWidth) * width

            return { width: width, height: height }
        }
    }

    viewMoreImages() {
        this.setState((state) => {
            return { paginationLimit: (state.paginationLimit + this.postsPerPage) }
        }, this.setImages)
    } 

    setImages(images=null) {
        let { paginationLimit } = this.state
        let postCount = 0
        let postsHtml
        let currentImages = images

        if (currentImages === null) currentImages = this.state.images

        if (currentImages.length > 0) {
            postsHtml = currentImages.map((image, i) => {
                if (postCount < paginationLimit) {
                    postCount++
                    const thumbnail = image.mediaDetails.sizes.filter(size => size.name === "medium")
                    if (image && thumbnail.length > 0) {
                        return (
                            <GalleryGridItem
                                key={i}
                                size={this.calcSize(thumbnail[0].height)}
                                thumbnail={image}
                                altText={image.altText}
                                onClick={() => {
                                    this.setState({
                                        activeImage: i 
                                    }, this.toggleModal())
                                }}
                            />
                        )
                    } else {
                        return null
                    }
                } else {
                    return null
                }
            })

            this.setState({
                postsHtml
            }, this.rePackUpdate())
        }
    }

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        })
    }

    render() {
        return(
            <>
                <div className="container pb-5" ref={gridWrap => this.gridWrap = gridWrap} style={{ paddingTop: this.state.paddingTop + "px" }}>
                    <div ref={grid => this.grid = grid}>
                        {this.state.postsHtml}
                    </div>
                </div>
                {this.state.paginationLimit < this.props.images.length &&
                    <div className="text-center">
                        <Button as="button" onClick={this.viewMoreImages} color="transparentPrimary">
                            View more images
                        </Button>
                    </div>
                }
                <GalleryLightbox 
                    modalOpen={this.state.modalOpen} 
                    toggleModal={this.toggleModal} 
                    images={this.state.images} 
                    activeImage={this.state.activeImage}
                />
            </>
        )
    }
}

export default GalleryGrid