import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { media } from "utils/Media"
import Text from "components/shared/Text"

const TicketInfoStyled = styled.td`
	padding-bottom: 1rem;
    cursor: pointer;

    h3 {
        font-size: 1.4rem;
        font-family: ${props => props.theme.font.family.special};
        letter-spacing: -1px;
        color: ${props => props.theme.colors.secondary};

        @media ${media.md} {
            font-size: ${props => props.theme.font.h2.size};
        }
        /* display: flex;
        align-items: center; */
    }
`

const InfoButton = styled.span`
	border: 0;
	background-color: transparent;
    color: ${props => props.theme.colors.secondary};
    font-size: 1.15rem;
	/* width: 20px;
	height: 20px;*/
	padding: 0;
	margin-left: .5rem;

    svg {
        max-height: 20px;
        max-width: 20px;
    }
`

const TicketInfo = (props) => {
    const ticket = props.ticket
    return(
        <TicketInfoStyled {...props}>
            {ticket && 
                <div role="button" onClick={props.toggleModal} onKeyPress={props.toggleModal} tabIndex={0}>
                    <Text as="h3" red special uppercase className="mb-2">
                        {ticket.title}
                        {ticket.hasPopup === "yes" && 
                            <InfoButton>
                                <span className="sr-only">More information</span>
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </InfoButton>
                        }
                    </Text>
                    <Text blue bold sm className="pb-2">{ticket.subTitle}</Text>
                    <Text as="div" sm dangerouslySetInnerHTML={{ __html: ticket.description }} className="d-none d-md-block" />
                </div>
            }
        </TicketInfoStyled>
    )
}

export default TicketInfo