import React, { Component } from "react"
import { Container, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import styled from "styled-components"
import scrollToElement from "scroll-to-element"
import { media } from "utils/Media"
import { LinkSearchReplace } from "utils/LinkSearchReplace"
import TicketPhasingMobile from "./TicketPhasingMobile"
import TicketPhasingDesktop from "./TicketPhasingDesktop"

const ContainerStyled = styled(Container)`
    padding-right: 0;

    @media ${media.md} {
        padding-right: 15px;
    }
`

const FilterDropdown = styled(UncontrolledDropdown)`
    position: relative;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    
    button {
        min-width: 280px;
    }

    .dropdown-toggle {
        border: 1px solid ${props => props.theme.colors.secondary};
        background-color: white;
        padding: .75rem;
        color: ${props => props.theme.colors.secondary};
        font-family: ${props => props.theme.font.family.bold};
        text-transform: uppercase;
        font-size: ${props => props.theme.font.size.sm};
        position: relative;
        text-align: left;
        padding-right: 3rem;

        &:after {
            display: none;
        }

        .icon {
            position: absolute;
            right: 1rem;
            font-size: 1rem;
        }
    }

    .dropdown-menu {
        margin: 0;
        padding: 0;
        width: 100%;
        
        button {
            color: ${props => props.theme.colors.secondary};
            text-transform: uppercase;
            font-family: ${props => props.theme.font.family.bold};
            font-size: ${props => props.theme.font.size.sm};
            padding: 1rem;

            &:hover {
                color: white;
                background-color: ${props => props.theme.colors.secondary};
            }
        }
    }

    &.show {
        z-index: 20;
        .dropdown-toggle {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .icon {
                &:before {
                    transform: rotate(180deg);
                    display: block;
                }
            }
        }

        .dropdown-menu {
            border: 1px solid ${props => props.theme.colors.secondary};
            border-top: 0;
            border-radius: 0;
        }
    }
`

class TicketPhasing extends Component {

    constructor(props) {
        super(props)

        this.state = {
            filterText: "SELECT YOUR TICKET TYPE (ALL)",
            windowWidth: 320
        }
    } 

    componentDidMount() {
        this.windowSize()
    }

    windowSize() {
        if (typeof window !== "undefined") {
            this.setState({
                windowWidth: window.outerWidth
            })
        }
    }

    setFilter(text, i) {
        this.setState({
            filterText: text
        })

        if (i !== -1) {
            const node = document.getElementById(`ticket${i}`)
            const headerHeight = document.getElementById('siteHeader').clientHeight
            const bodyPadding = document.body.classList.contains('mobile-book-tickets') ? 50 : 0

            scrollToElement(node, {
                offset: - (headerHeight + bodyPadding),
                duration: 500
            });
        }
    }

    render() {
		const ticketRows = this.props.data.acfTicketPhasing.ticketRows

        return (
            <ContainerStyled className="py-4 py-md-5">
                {this.props.data.acfTicketPhasing.displayFilter === "yes" &&
                    <FilterDropdown>
                        <DropdownToggle caret>
                            {this.state.filterText}
                            <span className="icon icon-down-open"></span>
                        </DropdownToggle>
                        
                        <DropdownMenu>
                            {this.state.filterText !== "SELECT YOUR TICKET TYPE (ALL)" && 
                                <DropdownItem 
                                    onClick={() => this.setFilter("SELECT YOUR TICKET TYPE (ALL)", -1)}
                                >
                                    SELECT YOUR TICKET TYPE (ALL)
                                </DropdownItem>
                            }
                            {ticketRows && ticketRows.map((ticket, i) => {
                                if (this.state.filterText !== ticket.title) {
                                    return (
                                        <DropdownItem 
                                            key={i}
                                            onClick={() => this.setFilter(ticket.title, i)}
                                        >
                                            {ticket.title}
                                        </DropdownItem>
                                    )
                                } else return ""
                            })}
                        </DropdownMenu>
                    </FilterDropdown>
                }

                {this.state.windowWidth < 768 ? (
                    <TicketPhasingMobile data={this.props.data} />
                ) : (
                    <TicketPhasingDesktop data={this.props.data} />
                )}

                {this.props.data.acfTicketPhasing.bottom_note && 
                    <div dangerouslySetInnerHTML={{ __html: LinkSearchReplace(this.props.data.acfTicketPhasing.bottomNote)}} className="pb-4 pt-2" />
                }
            </ContainerStyled>
        )
    }
}

export default TicketPhasing