import React from "react"
import { Row, Col, UncontrolledCollapse } from "reactstrap"
import SidebarBlock from "components/shared/SidebarBlock"
import Button from "components/shared/Button"
// import RolexApp from "components/shared/RolexApp"
// import WeatherApp from "components/shared/WeatherApp"
import Block5 from "./Block5"

const MobileView = (props) => {
    return (
        <div className="d-md-none pb-5">
            <Row>
                <Col xs={12} className="d-sm-none">
                    <Block5 data={props.data.block5} />
                </Col>
                <Col xs={12} sm={6} className="pb-2 p-sm-0">
                    <SidebarBlock id={props.data.block1.databaseId} linkBlock={true} />
                </Col>

                <Col xs={12} sm={6} className="pb-2 p-sm-0">
                    <SidebarBlock id={props.data.block4.databaseId} linkBlock={true} />
                </Col>

                <Col xs={12} sm={6} className="pb-2 p-sm-0">
                    <SidebarBlock id={props.data.block2.databaseId} linkBlock={true} />
                </Col>

                <Col xs={12} sm={6} className="pb-2 d-sm-none">
                    <SidebarBlock id={props.data.block3.databaseId} linkBlock={true} />
                </Col>

                <Col xs={12} className="d-sm-none text-center pt-2 pb-3">
                    <Button as="button" id="toggleBlocks" color="orangeOutline">
                        View more
                    </Button>
                </Col>

                <Col xs={12}>
                    <UncontrolledCollapse toggler="#toggleBlocks" className="row d-sm-flex">
                        <Col xs={12} sm={6} className="pb-2 p-sm-0 d-none d-sm-block">
                            <SidebarBlock id={props.data.block3.databaseId} linkBlock={true} />
                        </Col>
                        <Col xs={12} className="p-sm-0 d-none d-sm-block">
                            <Block5 data={props.data.block5} />
                        </Col>
                        <Col xs={12} sm={6} className="pb-2 p-sm-0">
                            {/* <WeatherApp /> */}
                            <SidebarBlock id={props.data.block7.databaseId} linkBlock={true} />
                        </Col>
                        
                        <Col xs={12} sm={6} className="pb-2 p-sm-0">
                            <SidebarBlock id={props.data.block6.databaseId} linkBlock={true} />
                        </Col>

                        <Col xs={12} className="pb-2 d-sm-none">
                            <SidebarBlock id={props.data.block9.databaseId} linkBlock={true} />
                        </Col>
                        <Col xs={12} sm={6} className="pb-2 p-sm-0">
                            <SidebarBlock id={props.data.block8.databaseId} linkBlock={true} />
                        </Col>
                        <Col xs={12} sm={6} className="p-sm-0 d-none d-sm-block">
                            <SidebarBlock id={props.data.block9.databaseId} linkBlock={true} />
                        </Col>
                    </UncontrolledCollapse>
                </Col>
            </Row>
        </div>
    )
}

export default MobileView