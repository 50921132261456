import React, { Component } from "react"
import { Table, Modal, ModalBody, Container, Row, Col, UncontrolledDropdown } from "reactstrap"
import styled, { css } from "styled-components"
import scrollToElement from "scroll-to-element"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import TicketBlock from "./TicketBlock"
import TicketInfo from "./TicketInfo"
import Text from "components/shared/Text"
import { GatsbyImage } from "gatsby-plugin-image";
import { media } from "utils/Media"

const ButtonWrap = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: .5rem;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: 100%;

    @media ${media.md} {
        top: 5rem;  
    }

    .button-text {
        font-size: ${props => props.theme.font.size.sm};
        color: ${props => props.theme.colors.secondary};
        padding-left: 160px;
        width: 100%;
        top: .25rem;
        position: absolute;
        text-align: center;

        @media ${media.md} {
            display: none;
        }
    }
`

const ButtonStyled = styled.button`
    background-color: transparent;
    border: 0;
    height: 2rem;
    width: 2rem;
    overflow: hidden;
    font-size: 1rem;
    padding-left: 0;
    padding-right: 1rem;
    color: ${props => props.theme.colors.secondary};
    position: absolute;
    left: 160px;

    @media ${media.md} {
        left: 400px;
    }

    ${props => props.right && css`
        padding-right: 0;
        padding-left: 1rem;
        left: auto;
        right: 0;

        @media ${media.md} {
            left: auto;
            right: 0;
        }
    `}
`

const ModalStyled = styled(Modal)`
    .modal-body {
        padding: 0;
        border-bottom: 2px solid ${props => props.theme.colors.secondary};

        .includes {
            list-style: none;

            li {
                position: relative;
                padding: .25rem 0;
                &:before {
                    content: "";
                    position: absolute;
                    height: .5rem;
                    width: .5rem;
                    background-color: ${props => props.theme.colors.secondary};
                    left: -1.25rem;
                    top: .65rem;
                    border-radius: 50%;
                }
            }
        }
    }

    .close {
        position: absolute;
        top: .5rem;
        right: 1rem;
        color: ${props => props.theme.colors.secondary};
        z-index: 10;
        cursor: pointer;
    }
`

const FilterDropdown = styled(UncontrolledDropdown)`
    position: relative;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    
    button {
        min-width: 280px;
    }

    .dropdown-toggle {
        border: 1px solid ${props => props.theme.colors.secondary};
        background-color: white;
        padding: .75rem;
        color: ${props => props.theme.colors.secondary};
        font-family: ${props => props.theme.font.family.bold};
        text-transform: uppercase;
        font-size: ${props => props.theme.font.size.sm};
        position: relative;
        text-align: left;
        padding-right: 3rem;

        &:after {
            display: none;
        }

        .icon {
            position: absolute;
            right: 1rem;
            font-size: 1rem;
        }
    }

    .dropdown-menu {
        margin: 0;
        padding: 0;
        width: 100%;
        
        button {
            color: ${props => props.theme.colors.secondary};
            text-transform: uppercase;
            font-family: ${props => props.theme.font.family.bold};
            font-size: ${props => props.theme.font.size.sm};
            padding: 1rem;

            &:hover {
                color: white;
                background-color: ${props => props.theme.colors.secondary};
            }
        }
    }

    &.show {
        z-index: 20;
        .dropdown-toggle {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .icon {
                &:before {
                    transform: rotate(180deg);
                    display: block;
                }
            }
        }

        .dropdown-menu {
            border: 1px solid ${props => props.theme.colors.secondary};
            border-top: 0;
            border-radius: 0;
        }
    }
`

// new styles
const TicketWrapMobile = styled.div`
    width: 100%;
    overflow-X: auto;
    position: relative;
    margin-top: 2rem;
    padding-top: 2rem;
    overflow-y: hidden;
`

const TicketRow = styled.div`
    display: flex;
`

// const TicketInfo = styled.div`
//     width: 250px;
// `

const TicketBlockWrap = styled.div`
    overflow-x: auto;
    position: relative;
`

const TicketBlockInnerWrap = styled.div`
    display: flex;
`

// const TicketBlock = styled.div`
//     width: 190px;
//     height: 180px;
//     border: 1px solid grey;
//     padding: .5rem;
//     display: flex;
//     align-items: center;
//     text-align: center;
// `


class TicketPhasing extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tableOverflowed: false,
            modal: [],
            filterText: "SELECT YOUR TICKET TYPE (ALL)",
            windowWidth: 320
        }

        this.thSize = {
            "sm" : {
                "thFirst" : 160,
                "thRegular" : 130
            },
            "lg" : {
                "thFirst" : 400,
                "thRegular" : 200
            }
        }

        this.ticketBlockWrap = []
        this.ticketInfo = []

        this.checkActivePhase = this.checkActivePhase.bind(this)
        this.sideScroll = this.sideScroll.bind(this)
        this.checkShadow = this.checkShadow.bind(this)
		this.toggleModal = this.toggleModal.bind(this)
		this.calcInnerWrapWidth = this.calcInnerWrapWidth.bind(this)
    } 

    componentDidMount() {
        this.windowSize()
        for (let i = 0; i < this.ticketBlockWrap.length; i++) {
            this.ticketBlockWrap[i].addEventListener('scroll', this.checkShadow, { passive: true })
        }
    }

    componentWillUnmount() {
        for (let i = 0; i < this.ticketBlockWrap.length; i++) {
            this.ticketBlockWrap[i].removeEventListener('scroll', this.checkShadow, { passive: true })
        }
    }

    windowSize() {
        if (typeof window !== "undefined") {
            this.setState({
                windowWidth: window.outerWidth
            })

            this.checkActivePhase(window.outerWidth)
        }
    }

    toggleModal(i) {
        let modals = this.state.modal

        modals[i] = !this.state.modal[i]

		this.setState(({
			modal: modals
		}))
	}

    checkActivePhase(windowSize) {
        if (windowSize < 768) {
            const ticketRows = this.props.data.acfTicketPhasing.ticketRows
            for (let i = 0; i < ticketRows.length; i++) {
                let steps = 0
                for (let t = 0; t < ticketRows[i].ticketPhase.length; t++) {
                    if (ticketRows[i].ticketPhase[t].status === "active") {
                        this.sideScroll("right", steps, this.ticketBlockWrap[i])
                        console.log(steps, this.ticketBlockWrap[i])
                        break
                    }
                    steps++
                }
            }
        }
    }

    sideScroll(direction, preDistance=1, element) {
        const speed = 25
        const step = 10

        let distance = (this.thSize["sm"]["thRegular"] * preDistance) 
        if (this.state.windowWidth > 767) distance = (this.thSize["lg"]["thRegular"] * preDistance) - 10

        console.log(distance)

        let scrollAmount = 0

        let slideTimer = setInterval(() => {
            if (direction === 'left') {
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if (scrollAmount >= distance) {
                // this.checkShadow()
                window.clearInterval(slideTimer);
            }
        }, speed);
    }

    checkShadow() {
        if (this.ticketBlockWrap.length  > 0) {
            for (let i = 0; i < this.ticketBlockWrap.length; i++) {
                this.ticketBlockWrap[i].scrollLeft === 0 ? this.ticketInfo[i].classList.remove('display-shadow') : this.ticketInfo[i].classList.add('display-shadow')
            }
        }
    }

    setFilter(text, i) {
        this.setState({
            filterText: text
        })

        if (i !== -1) {
            const node = document.getElementById(`ticket${i}`)
            const headerHeight = document.getElementById('siteHeader').clientHeight
            const bodyPadding = document.body.classList.contains('mobile-book-tickets') ? 50 : 0

            scrollToElement(node, {
                offset: - (headerHeight + bodyPadding),
                duration: 500
            });

            // window.scrollTo({
            //     top: (node.getBoundingClientRect().top + window.scrollY) - (headerHeight + bodyPadding), // 
            //     behavior: 'smooth'
            // })
        }
    }

    calcInnerWrapWidth() {
        const phasesCount = this.props.data.acfTicketPhasing.phases.length
        const ticketBlockWidth = this.thSize["sm"]["thRegular"]
        const innerWidth = ticketBlockWidth * phasesCount

        return { width: `${innerWidth}px` }
    }

    render() {
		const phases = this.props.data.acfTicketPhasing.phases
		const ticketRows = this.props.data.acfTicketPhasing.ticketRows

        return <>
            <TicketWrapMobile>
                {/* {this.state.tableOverflowed && */}
                    <ButtonWrap>
                        <ButtonStyled>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <span className="sr-only">Scroll back</span>
                        </ButtonStyled>
                        <div className="button-text">
                            Swipe to view options
                        </div>
                        <ButtonStyled right>
                            <FontAwesomeIcon icon={faChevronRight} />
                            <span className="sr-only">Scroll forwards</span>
                        </ButtonStyled>
                    </ButtonWrap>
                {/* } */}
                {ticketRows && ticketRows.map((ticket, i) => {
                    return(
                        <TicketRow key={i} ref={ticketInfo => this.ticketInfo[i] = ticketInfo}>
                            <TicketInfo  
                                ticket={ticket}
                                toggleModal={() => this.toggleModal(i)}
                                style={{ flex: `0 0 ${this.thSize["sm"]["thFirst"]}px` }}
                                // first={i === 0} 
                                // last={i === (ticketRows.length - 1)}
                            />
                            <TicketBlockWrap ref={ticketBlockWrap => this.ticketBlockWrap[i] = ticketBlockWrap}>
                                <TicketBlockInnerWrap style={this.calcInnerWrapWidth()}>
                                    {ticket.ticketPhase && ticket.ticketPhase.map((phase, i) => {
                                        return (
                                            <TicketBlock 
                                                key={i} 
                                                phase={phase} 
                                                phases={phases} 
                                                soldOut={phase.soldOut} 
                                                status={phase.status} 
                                                style={{ width: `${this.thSize["sm"]["thRegular"]}px` }}
                                            />
                                        )
                                    })}
                                </TicketBlockInnerWrap>
                            </TicketBlockWrap>
                        </TicketRow>
                    )
                })}
            </TicketWrapMobile>
            {/* Modals */}
            {ticketRows && ticketRows.map((ticket, i) => {
                return (
                    <React.Fragment key={i}>
                        {ticket.hasPopup === "yes" && 
                            <ModalStyled
                                isOpen={this.state.modal[i]} 
                                toggle={() => this.toggleModal(i)} 
                                centered={true} 
                                size="lg">
                                <ModalBody>
                                    <Container fluid>
                                        <button className="close" onClick={() => this.toggleModal(i)}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                        <Row>
                                            <Col className="d-none d-md-block col-md-5 pl-0">
                                                {ticket.popupImage && 
                                                    <GatsbyImage
                                                        image={ticket.popupImage.localFile.childImageSharp.gatsbyImageData}
                                                        alt={ticket.popupImage.altText} />  
                                                }
                                            </Col>
                                            <Col className="col-md-7 pt-3">
                                                <Text as="h3" uppercase secondary special xl>{ticket.title}</Text>
                                                <Text blue bold>{ticket.subTitle}</Text>
                                                <Text>{ticket.description}</Text>
                                                {ticket.popupTicketIncludes &&
                                                    <ul className="includes">
                                                        {ticket.popupTicketIncludes.map((include, i) => {
                                                            return(
                                                                <li key={i}>
                                                                    <Text>{include.text}</Text>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                }
                                            </Col>
                                        </Row>
                                    </Container>
                                </ModalBody>
                            </ModalStyled>
                        }
                    </React.Fragment>
                );
            })}
        </>;
    }
}

export default TicketPhasing