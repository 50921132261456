import React, { Component } from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

const AdWrap = styled.div`
  > div {
	display: flex;
	justify-content: center;
  }
`

class BellyBandReusable extends Component {

	componentDidMount() {
		if (typeof window !== "undefined") {
			const { accountId, name, counter } = this.props
			let id = `googlead${name.toLowerCase()}-${counter}`
			id = id.replace(" ", "")
			const postscribe = require('postscribe');
			postscribe(this.ad, `<script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
			
			<script>
				window.googletag = window.googletag || {cmd: []};
				var gptAdSlots = [];
				var supportedSizes = [[970, 250], [728, 90], [320, 50]];
				googletag.cmd.push(function() {
					var bottomAdSizes = googletag.sizeMapping().addSize([992, 1], [970, 250]).addSize([768, 1], [728, 90]).addSize([0, 0], [320, 50]).build(); //If browser viewport is more than 1024×768 show creative banner 970×250. If browser viewport is more than 980×690, show creative banner 728×90, if browser viewport is less than 640×480 show creative banner 120×60
				
					gptAdSlots[0] = googletag.defineSlot('/${accountId}/${name}', supportedSizes, '${id}').defineSizeMapping(bottomAdSizes).addService(googletag.pubads()); //It tells the ad server what creative to serve on the ad slot
					googletag.pubads().setCentering(true);  
					googletag.enableServices(); //This step instructs ad server to start fetching the ads
				});
			</script>
			<div id='${id}' class="ad">
			  <script>
				googletag.cmd.push(function() { googletag.display('${id}'); });
			  </script>
			</div>`)
		}
	}

	componentWillUnmount() {
		if (typeof window !== "undefined" && window.googletag) {
			window.googletag.destroySlots()
		}
	}

	render() {
		return(
			<AdWrap className="p-2" ref={ad => this.ad = ad} />
		)
	}
}

export default (props) => (
  <StaticQuery
	  query={graphql`
			query {
				allWp {
					edges {
						node {
							options {
								acfOptions {
									googleAdsAccountId
								}
							}
						}
					}
				}
			}
	  	`}
	  	render={data => {
			const { bellybandName } = props.block
			if (
				data.allWp.edges[0].node.options.acfOptions.googleAdsAccountId !== null &&
				bellybandName !== null
			) {
				return (
					<BellyBandReusable 
						accountId={data.allWp.edges[0].node.options.acfOptions.googleAdsAccountId} 
						name={bellybandName}
						counter={props.counter}
					/>
				)	
			} else return ""
	  	}}
  	/>
)