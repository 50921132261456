import React from "react"
import styled, { css } from "styled-components"
import WPLink from "components/shared/WPLink"
import { media } from "utils/Media"

const TicketBlockStyled = styled.td`
	/* width: 200px; */
	/* display: flex; */
	text-align: center;
	position: relative;
	padding: .25rem !important;
    position: relative;
    opacity: 0.3;
    pointer-events: none;

    &:before {
        content: "";
        position: absolute;
        border: 2px solid ${props => props.theme.colors.greyLight};
        left: .25rem;
        right: .25rem;
        top: .25rem;
        bottom: .25rem;
        z-index: 1;
    }

    a {
        margin-top: 1rem;
        padding: 1rem;
        width: 100%;
        color: ${props => props.theme.colors.secondary};
    }

	.inner {
		width: 100%;
		text-align: center;
        padding: .5rem;
        text-transform: uppercase;
        position: relative;
        z-index: 2;

        @media ${media.md} {
            padding: 1rem;
        }

        p {
            font-family: ${props => props.theme.font.family.special};
            letter-spacing: -1px;
        }
	}

    .small-title {
        font-size: 1rem;
        line-height: 1;
        margin-bottom: .25rem !important;
    }

    .text-wrap {
        min-height: 65px;
    }

    .title {
        font-size: 1.5rem;
        line-height: 1;
        
        @media ${media.md} {
            font-size: 2rem;
        }
    }

    .price {
        font-size: 2.6rem;
        line-height: 1;
    }

    .sold-out {
        font-size: 1.5rem;
        padding-top: 1rem;

        @media ${media.md} {
            font-size: 2rem;
        }
    }

    ${props => props.soldOut === "yes" && css`
        opacity: 0.5;

        .title, .small-title, .price {
            text-decoration: line-through;
        }
        .sold-out {
            color: ${props => props.theme.colors.white};
        }
    `}

    ${props => props.status === "yes" && css`
        opacity: 1;
        color: white;
        pointer-events: auto;

        &:before {
            background-color: ${props => props.theme.colors.secondary};
            border-color: ${props => props.theme.colors.secondary};
        }

        a {
            border-color: white;
            color: white;
        }

        &:hover {
            a {
                border-color: ${props => props.theme.colors.blue};
            }

            &:before {
                background-color: ${props => props.theme.colors.primary};
                border-color: ${props => props.theme.colors.primary};
            }
        }
    `}
`

const TicketBlock = (props) => {
    const phase = props.phase
    return(
        <TicketBlockStyled {...props}>
            <div className="inner">
                <div className="text-wrap">
                    {phase.smallTitle &&
                        <p className="small-title">{phase.smallTitle}</p>
                    }
                    {phase.title && phase.price === null &&
                        <p className="title">{phase.title}</p>
                    }
                    {phase.price &&
                        <p className="price">{phase.price}</p>
                    }
                </div>
                {phase.link  && phase.link !== "" && phase.soldOut === "no" &&
                    <WPLink url={phase.link} target="_blank" button color="red">
                        <span className="d-none d-md-inline">book</span> tickets 
                    </WPLink>
                }
                {phase.soldOut === "yes" &&
                    <p className="sold-out">SOLD OUT</p>
                }
            </div>
        </TicketBlockStyled>
    )
}

export default TicketBlock