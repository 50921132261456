import React from "react"
import ContainerMax from "components/shared/ContainerMax"
import DesktopView from "./DesktopView"
import TabletView from "./TabletView"
import MobileView from "./MobileView"

const LinkBlocks = (props) => {
    return (
        <ContainerMax maxWidth="1920">
            {/* Had to seperate into different views becuase of unnatural stacking order */}
            <DesktopView data={props.block} />
            <TabletView data={props.block} />
            <MobileView data={props.block} />
        </ContainerMax>
    )
}


export default LinkBlocks