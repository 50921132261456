import React, { Component } from "react"
import { Container, Row, Col, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import GalleryGrid from "components/shared/Gallery/GalleryGrid"
import BlockTitle from "components/shared/BlockTitle"
import FilterDropdown from "components/shared/FilterDropdown"

class Galleries extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedGallery: "",
            filterText: "SELECT (ALL)",
            images: []
        }
    }

    componentDidMount() {
        this.setImages("SELECT (ALL)")
    }

    setImages(id) {
        let images = []

        if (id === "SELECT (ALL)") {
            this.props.block.galleries.forEach(gallery => {
                images.push(gallery.acfGalleries.images)
            })
        } else {
            images = this.props.block.galleries[id].acfGalleries.images
        }

        images = images.flat()

        this.setState({
            images
        })
    }

    setFilter(id, text) {
        this.setState({
            filter: id,
            filterText: text
        })

        const filter = id !== "" ? id : text

        this.setImages(filter)
    }

    render() {
        const { block } = this.props
        const  { galleries } = block

        return(
            <div className="py-4">
                {block.title && 
                    <Container>
                        <BlockTitle base>{block.title}</BlockTitle>
                    </Container>
                }
                
                {galleries.length > 1 &&
                    <Container>
                        <Row>
                            <Col>
                                <FilterDropdown>
                                    <DropdownToggle caret>
                                        {this.state.filterText}
                                        <span className="icon icon-down-open"></span>
                                    </DropdownToggle>
                                    
                                    <DropdownMenu>
                                        {this.state.filterText !== "SELECT (ALL)" && 
                                            <DropdownItem 
                                                onClick={() => this.setFilter("", "SELECT (ALL)")}
                                            >
                                                SELECT (ALL)
                                            </DropdownItem>
                                        }
                                        {galleries && galleries.map((gallery, i) => {
                                            if (this.state.filterText !== gallery.title) {
                                                return (
                                                    <DropdownItem 
                                                        key={i}
                                                        onClick={() => this.setFilter(i, gallery.title)}
                                                    >
                                                        {gallery.title}
                                                    </DropdownItem>
                                                )
                                            } else return ""
                                        })}
                                    </DropdownMenu>
                                </FilterDropdown>
                            </Col>
                        </Row>
                    </Container>
                }
                <GalleryGrid images={this.state.images} displayMore={true} />
            </div>
        )
    }
}

export default Galleries