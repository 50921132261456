import React from "react"
import { Container } from "reactstrap"
import WPLink from "components/shared/WPLink"
import GalleryGrid from "components/shared/Gallery/GalleryGrid"
import BlockTitle from "components/shared/BlockTitle"

const GalleryMasonry = (props) => {
    const { block } = props

    return(
        <div className="py-4">
            {block.title && 
                <Container>
                    <BlockTitle base>{block.title}</BlockTitle>
                </Container>
            }
            <GalleryGrid images={block.images} displayMore={false} />
            {block.link &&
                <Container>
                    <div className="text-center">
                        <WPLink url={block.link.url} target={block.link.target} button color="transparentPrimary">
                            {block.link.title}
                        </WPLink>
                    </div>
                </Container>
            }
        </div>
    )
}

export default GalleryMasonry