import React from "react"
import ContainerMax from "components/shared/ContainerMax";
import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";
import { media } from "utils/Media";
import TicketHospitality from "components/shared/TicketHospitality";
import WPLink from "components/shared/WPLink"

const TicketsStyled = styled(ContainerMax)`
	padding-top: 50px;
	padding-bottom: 24px;
	background: ${props => props.theme.colors.whiteOff};

    @media ${media.md} {
		padding-bottom: 50px;
    }	

	&.no-title {
		background: transparent;
		padding-top: 0;
		padding-bottom: 0;
		margin-top: 24px;
		margin-bottom: 24px;
	    @media ${media.md} {
			margin-top: 50px;
			/* margin-bottom: 50px; */
	    }	    
	    @media ${media.lg} {
			/* margin-bottom: 100px; */
	    }
	}

	h3 {
		font-size: 1.125rem;/*18*/
		font-family: ${props => props.theme.font.family.base};
		color: ${props => props.theme.colors.grey1};
		line-height: 1.44;
		color: #1D2E41;
		margin: 12px 0 16px;
		font-weight: 400;	
		
	    @media ${media.md} {
			font-size: 1.5rem;/*24*/
			margin: 0 0 50px;
			line-height: 1.5;
	    }
	}

	@media ${media.md} {
		> .container {
			> .row {
				margin: 0 -10px;
				justify-content: center;
				> .col-md-4 {
					padding: 0 10px;
				}
			}
		}
	}
`

const Tickets = (props) => {
	return (
		<TicketsStyled className={props.block.title ? '' : 'no-title'} maxWidth="1920">
			<Container fluid>
				<Row>
					<Container>
						{props.block.title ? <h3 dangerouslySetInnerHTML={{ __html: props.block.title }} />	: null }
						<Row>
							{props.block.tickets && props.block.tickets.map((node, i) => (
								<Col md="4" key={i}>
									<TicketHospitality data={node} key={i} />
								</Col>
							))}
							{/*do we have a view all link?*/}
							{props.block.button &&
								<div className="text-center pt-4">
									<WPLink url={props.block.button.url} target={props.block.button.target} button color="transparentPrimary">
										{props.block.button.title}
									</WPLink>
								</div>
							}
						</Row>
					</Container>
				</Row>
			</Container>
		</TicketsStyled>
	)
}


export default Tickets