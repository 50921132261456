
import React, { Component } from "react";
import styled from "styled-components";
import { media } from "utils/Media";
import { Row, Col } from "reactstrap";
import WPLink from "components/shared/WPLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";

const TickeyHospitalityBlock = styled.div`
	background: ${props => props.theme.colors.secondary};
	padding: .25rem 0 0;
	color: ${props => props.theme.colors.white};
	text-align: center;
	margin-bottom: 30px;
	position: relative;
	z-index: 10;
	/* border: 1px solid ${props => props.theme.colors.secondary}; */

	h4 {
		font-size: 1.5rem;/*24*/
		line-height: 1.5;
		margin: 20px 0;
		color: white;
		font-family: ${props => props.theme.font.family.bold};
		color: ${props => props.theme.colors.primary};

	    @media ${media.md} {
  			margin: 21px 0 25px;
	    }    

	    @media ${media.lg} {
  			margin: 20px 0;
	    }
	}
	p.description {
		font-size: 1rem;/*16*/
		line-height: 1.5;
		margin: 21px 0;
		font-family: ${props => props.theme.font.family.base};
		color: ${props => props.theme.colors.primary};

	    @media ${media.md} {
  			margin: 25px 0 14px;
  			min-height: 48px;
	    }    

	    @media ${media.lg} {
  			margin: 20px 0;
  			min-height: 0;
	    }
	}

    @media ${media.lg} {
		transition: transform .2s, background-color .2s, color .2s;
		transform: scale(1);

		&:hover {
			border-color: ${props => props.theme.colors.primary}; 
			z-index: 2;
			transform: scale(1.06);
			/* box-shadow: 0 8px 20px 0 #0000001a; */
			background: ${props => props.theme.colors.primary};
			color: ${props => props.theme.colors.white};

			h4 {
				color: ${props => props.theme.colors.secondary};
			}

			p {
				color: ${props => props.theme.colors.white};
			}

			.prices .price {
				p.price {
					color: ${props => props.theme.colors.secondary};
				}
				p.subtitle, h4 {
					color: ${props => props.theme.colors.white};
				}
			}

			.booking-links a { 
				border-color: ${props => props.theme.colors.secondary};
				background: ${props => props.theme.colors.secondary};
			    color: ${props => props.theme.colors.white};
			}
		}
    }	

	.prices {	
		justify-content: center;	
		div.price {
			margin-bottom: 20px;
			svg {
				height: 20px;
				width: 20px;
				margin: 0 auto 3px;					
				display: block;
				background-repeat: no-repeat;
				background-position: 0 0;
				background-size: cover;
				color: ${props => props.theme.colors.white};

				&.ticket {
					width: 27px;
					height: 27px;
				}

				&.multi {
					width: 100px;
					height: 27px;
				}

				&.champagne {
					width: 8px;
					height: 27px;
				}
			}
			h4 {
				font-family: ${props => props.theme.font.family.base};
  				font-size: 1.125rem;/*18*/
  				line-height: 1;
				color: ${props => props.theme.colors.primary};
  				margin: .5rem 0 .75rem 0; 
				text-transform: uppercase;

			    @media ${media.md} {
		  			font-size: .85rem;/*16*/
			    }    
			}
			p.price {
				font-size: 3.25rem;/*60*/
				line-height: 1;
				color: ${props => props.theme.colors.white};
				margin: .25rem 0;
				font-family: ${props => props.theme.font.family.base};

			    @media ${media.md} {
		  			font-size: 3.25rem;/*52*/
			    }     

                &.price--small {
                    font-size: 1.5rem;
                    line-height: 3;
                }   
			}
			p.subtitle {
				font-family: ${props => props.theme.font.family.base};
  				font-size: 1.125rem;/*18*/
  				line-height: 1.44;
  				color: ${props => props.theme.colors.primary};
  				margin: 0; 

			    @media ${media.md} {
		  			font-size: .85rem;/*16*/
			    }    
			}
		}
	}
	.booking-links {
		margin: 0 -5px;
		a {
			background: transparent;
			border: 1px solid white;
			color: ${props => props.theme.colors.white};
			margin: 0 5px 10px;			
			@media ${media.lg} {
				padding: 1rem 20px;
			}
		}
	}
	.package-details {
		margin: 14px 0 0;
		background-color: ${props => props.theme.colors.white};
		color: ${props => props.theme.colors.secondary};
		border: 1px solid ${props => props.theme.colors.secondary};

	    @media ${media.md} {
  			position: relative;
	    }  

  		button {
  			appearance: none;
  			border-radius: 0;
  			background: transparent;
  			border: none;
			font-family: ${props => props.theme.font.family.bold};
			font-size: 0.875rem;
			line-height: 1;
			color: ${props => props.theme.colors.secondary};
			text-transform: uppercase;
			margin: 0;
			padding: 15px 0 5px;
			display: block;
			width: 100%;
			outline: none;
			> svg {
				font-size: 24px;
			}
  		}

		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;
			text-align: left;

		    @media ${media.md} {
				position: absolute;
				left: 0;
				top: 100%;
				width: 100%;
				z-index: 10;
				background-color: ${props => props.theme.colors.white};
		    }  

			li {
				padding: 6px 20px 6px 50px;
				position: relative;

			    @media ${media.md} {
		  			padding: 6px 10px 6px 40px;
			    }     

			    @media ${media.lg} {
		  			padding: 6px 20px 6px 50px;
			    }  

				&:nth-child(odd) {
					background-color: ${props => props.theme.colors.whiteOff};
				}

				svg {
					position: absolute;
					top: 7px;
					left: 20px;
                    height: 24px;
				    @media ${media.md} {
			  			left: 10px;
				    }     
				    @media ${media.lg} {
			  			left: 20px;
				    }  
					&.check {
						font-size: 20px;
					}
				}

				.text {
					p {
					    font-size: 0.875rem;/*14*/
					    line-height: 1.5;
					    color:  ${props => props.theme.colors.blackOff};
					    i,em {
					    	font-family: ${props => props.theme.font.family.italic};
					    }
					    strong,b {
					    	font-family: ${props => props.theme.font.family.bold};
					    }
					  	a {
					  		text-decoration: underline;
					  	}
					}
				}
			}
		}
    }
`

export default class TicketHospitality extends Component {	
	
    constructor(props) {
        super(props)
        this.state = {
            detailsOpen: false
        }
    }

    toggleDetails = () => {
    	this.setState({ detailsOpen: !this.state.detailsOpen });
    }
    
	render() {
		const data = this.props.data;
		
		return (
	        <TickeyHospitalityBlock>
	            <h4 dangerouslySetInnerHTML={{ __html: data.title }} />
	            <p className="description" dangerouslySetInnerHTML={{ __html: data.acfTickets.description }} />
	            
	            <Row className="prices">
	                {data.acfTickets.prices && data.acfTickets.prices.map((price, i) => (
	                    <Col lg="6" className="price" key={i}>
							{(() => {
								switch(price.icon) {
									case 'ticket':
										return <TicketIcon className="ticket" />
									case 'multi':
										return <TicketsIcon className="multi" />
									case 'champagne':
										return <ChampagneIcon className="champagne" />
									default:
										return null
								}
							})()}
							<h4 dangerouslySetInnerHTML={{ __html: price.title }} />
							{ price.price === "SOLD OUT" ? (
									<p className="price price--small" dangerouslySetInnerHTML={{ __html: price.price }} />
							) : (
									<p className="price" dangerouslySetInnerHTML={{ __html: price.price }} />
							)}
							{
								price.subtitle
								? <p className="subtitle" dangerouslySetInnerHTML={{ __html: price.subtitle }} />
								: null
							}
	                    </Col>
	                ))}
	            </Row>
	            <div className="booking-links">
                	{data.acfTickets.links && data.acfTickets.links.map((link, i) => {
                		if (link.__typename==='WpTicket_Acftickets_Links_UrlLink') {
                			return <WPLink url={link.link.url} target={link.link.target} button key={i}>
	                            {link.link.title}
	                        </WPLink>
                		} else if (link.__typename==='WpTicket_Acftickets_Links_PdfDownload') {
                            return <WPLink url={link.file.mediaItemUrl} target="_blank" button key={i}>
	                            {link.buttonText}
	                        </WPLink>
                		}
                		return null;
                	})}
	            </div>
	            
	            {data.acfTickets.accordion && data.acfTickets.accordion.length>0 &&
	            	<div className={this.state.detailsOpen ? 'package-details open' : 'package-details'}>
	            		<button onClick={this.toggleDetails}>
	    	            	{data.acfTickets.packageDetailsTitle}<br />
							{this.state.detailsOpen ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} /> }
	    	            	
    	            	</button>
    	            	{
    	            		this.state.detailsOpen
                    		? <ul>
                	            {data.acfTickets.accordion.map((item) => (
            	                    <li>
										{(() => {
											switch(item.icon) {
												case 'Tick':
													return <FontAwesomeIcon className="check" icon={faCheck} />
												case 'Champagne':
													return <DoubleChampagne className="champagne" />
												default:
													return null
											}
										})()}
            	                      	<div className="text" dangerouslySetInnerHTML={{ __html: item.text }} />
            	                    </li>
            	                ))}
            	            </ul>
            	            : null
    	            	}
        	        </div>
	            }			            	              
	            
	        </TickeyHospitalityBlock>
		)
	}
}

export function TicketIcon(props) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 26.5 26.5" style={{enableBackground: 'new 0 0 26.5 26.5'}} xmlSpace="preserve" width="26.5" height="26.5" {...props}>
        <g transform="translate(0 -.001)">
          <g transform="rotate(-45 21.34 8.839)">
            <path fill="currentColor" id="Exclusion_1" d="M25,12.5H0v-3c0.9,0,1.7-0.3,2.3-1c0.6-0.6,1-1.4,1-2.3C3.2,4.5,1.8,3,0,3V0h25v3
            				c-1.8,0-3.3,1.5-3.3,3.2s1.5,3.2,3.3,3.2V12.5z M5.5,2.5V10h14V2.5H5.5z" />
          </g>
        </g>
    </svg>
  );
}
export function TicketsIcon(props) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 70.5 28.1" style={{enableBackground: 'new 0 0 70.5 28.1'}} xmlSpace="preserve" width="70.5" height="28.1" {...props}>
        <g transform="translate(-101.178 -194.998)">
          <g transform="translate(143.603 194.998)">
            <g transform="rotate(-45 22.632 9.374)">
              <path fill="currentColor" d="M26.5,13.3H0l0-3.2c0.9,0,1.8-0.4,2.4-1c0.6-0.6,1-1.5,1-2.4c0-1.9-1.5-3.4-3.4-3.4V0h26.5v3.2
              					c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4l0,0V13.3z M5.8,2.7v8h14.8v-8H5.8z" />
            </g>
          </g>
          <g transform="translate(122.391 194.998)">
            <g transform="rotate(-45 22.632 9.374)">
              <path fill="currentColor" d="M26.5,13.3H0v-3.2c0.9,0,1.8-0.4,2.4-1c0.6-0.6,1-1.5,1-2.4c0-1.9-1.5-3.4-3.4-3.4L0,0h26.5v3.2
              					c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4l0,0V13.3z M5.8,2.7v8h14.8v-8H5.8z" />
            </g>
          </g>
          <g transform="translate(101.178 194.998)">
            <g transform="rotate(-45 22.632 9.374)">
              <path fill="currentColor" d="M26.5,13.3H0v-3.2c0.9,0,1.8-0.4,2.4-1c0.6-0.6,1-1.5,1-2.4c0-1.9-1.5-3.4-3.4-3.4V0l26.5,0v3.2
              					c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4l0,0V13.3z M5.8,2.7v8h14.8v-8H5.8z" />
            </g>
          </g>
        </g>
    </svg>
  );
}
export function ChampagneIcon(props) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 8 25.3" style={{enableBackground: 'new 0 0 8 25.3'}} xmlSpace="preserve" width={8} height="25.3" {...props}>
      <path fill="currentColor" d="M4,19c-2.2,0-4-1.8-4-4V0h8v15C8,17.2,6.2,19,4,19z M4.5,15
      			C4.2,15,4,15.2,4,15.5S4.2,16,4.5,16S5,15.8,5,15.5S4.8,15,4.5,15z M3.5,12C3.2,12,3,12.2,3,12.5S3.2,13,3.5,13S4,12.8,4,12.5
      			S3.8,12,3.5,12z M5,8C4.4,8,4,8.4,4,9s0.4,1,1,1s1-0.4,1-1S5.6,8,5,8z M3,3C2.4,3,2,3.4,2,4s0.4,1,1,1s1-0.4,1-1S3.6,3,3,3z" />
      <g transform="translate(.002 18.392)">
        <path stroke="currentColor" style={{fill: 'none'}} d="M0,6.4h8" />
        <path stroke="currentColor" style={{fill: 'none'}} d="M4,6.4V0" />
      </g>
    </svg>
  );
}
export function DoubleChampagne(props) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 22.1" style={{enableBackground: 'new 0 0 16 22.1'}} xmlSpace="preserve" width={16} height="22.1" {...props}>
      <g transform="translate(-349 -2151)">
          <g transform="translate(358 2151)">
            <path style={{fill: '#323741'}} d="M3.5,15.8c-1.9,0-3.5-1.6-3.5-3.5V0h7v12.3C7,14.3,5.4,15.8,3.5,15.8z
            				 M3.9,12.5c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4C4.4,12.7,4.2,12.5,3.9,12.5L3.9,12.5z M3.1,10
            				c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4C3.5,10.2,3.3,10,3.1,10L3.1,10z M4.4,6.7
            				c-0.5,0-0.8,0.4-0.8,0.9S4,8.4,4.5,8.3c0.4,0,0.8-0.4,0.8-0.8C5.2,7,4.8,6.7,4.4,6.7z M2.6,2.5c-0.5,0-0.9,0.4-0.9,0.8
            				c0,0.5,0.4,0.8,0.9,0.8c0.5,0,0.9-0.4,0.9-0.8C3.5,2.9,3.1,2.5,2.6,2.5z" />
            <g transform="translate(.002 15.235)">
              <path style={{fill: 'none', stroke: '#323741'}} d="M0,6.4h7" />
              <path style={{fill: 'none', stroke: '#323741'}} d="M3.5,6.4V0" />
            </g>
          </g>
          <g transform="translate(349 2151)">
            <path style={{fill: '#323741'}} d="M3.5,15.8c-1.9,0-3.5-1.6-3.5-3.5V0h7v12.3C7,14.3,5.4,15.8,3.5,15.8z
            				 M3.9,12.5c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4C4.4,12.7,4.2,12.5,3.9,12.5L3.9,12.5z M3.1,10
            				c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4C3.5,10.2,3.3,10,3.1,10L3.1,10z M4.4,6.7
            				c-0.5,0-0.8,0.4-0.8,0.9S4,8.4,4.5,8.3c0.4,0,0.8-0.4,0.8-0.8C5.2,7,4.8,6.7,4.4,6.7z M2.6,2.5c-0.5,0-0.9,0.4-0.9,0.8
            				c0,0.5,0.4,0.8,0.9,0.8c0.5,0,0.9-0.4,0.9-0.8C3.5,2.9,3.1,2.5,2.6,2.5z" />
            <g transform="translate(.002 15.235)">
              <path style={{fill: 'none', stroke: '#323741'}} d="M0,6.4h7" />
              <path style={{fill: 'none', stroke: '#323741'}} d="M3.5,6.4V0" />
            </g>
          </g>
      </g>
    </svg>
  );
}