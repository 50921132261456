import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { Table, Modal, ModalBody, Container, Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import styled, { css } from "styled-components"
import classNames from "classnames"
import scrollToElement from "scroll-to-element"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import TicketPhasingV2 from "../TicketPhasingv2/TicketPhasing"
import TicketBlock from "./TicketBlock"
import TicketHeading from "./TicketHeading"
import TicketInfo from "./TicketInfo"
import Text from "components/shared/Text"
import { GatsbyImage } from "gatsby-plugin-image";
import { media } from "utils/Media"
import { LinkSearchReplace } from "utils/LinkSearchReplace"

const ContainerStyled = styled(Container)`
    padding-right: 0;

    @media ${media.md} {
        padding-right: 15px;
    }
`

const GeneralTableWrap = styled.div`
    position: relative;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    
    &:after {
        /* used to hide scroll bars */
        content: "";
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 25px;
        background-color: white;
        bottom: 0;
        left: 0; 
    }

    &.auto {
        width: auto;
        margin-right: 1rem;
    }
`

const ScrollContainer = styled.div`
    width: 100%;
    overflow-x: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none;
    }
`

const ScrollOuter = styled.div`
    position: relative;
    &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 30px;
        background-image: linear-gradient(to left, rgba(255, 255, 255, .9), transparent 70%);
        opacity: 1;
        transition: ${props => props.theme.transitionBase};
        display: none;
    }

    .tableOverflowed & {
        &:after {
            display: none;

            @media ${media.md} {
                display: block;
            }
        }
    }

    &.hide-white-shadow {
        &:after {
            opacity: 0;
        }
    }
`

const TableStyled = styled(Table)`
    margin: 0;

    th, td {
        border: 0 !important;
    }

    &.table-striped tbody tr:nth-of-type(odd) {
        /* background-color: #eee; */
    }

    p {
        margin: 0 !important;
    }
`

const TableFixed = styled(TableStyled)`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    tr {
        background-color: transparent !important;
    }
    th, td {
        opacity: 0;
        visibility: hidden;
        border-color: transparent !important;
        position: relative;
        background-color: white;

        &:first-child {
            opacity: 1;
            visibility: visible;
            transition: ${props => props.theme.transitionBase};
        }

        &:after {
            content: "";
            position: absolute;
            left: 100%;
            top: 1px;
            width: 20px;
            height: calc(100% + 1px);
            z-index: 1;
            background-image: linear-gradient(to right, rgba(0, 0, 0, .05), transparent);
            opacity: 0;
        }

        .display-shadow & {
            &:after {
                opacity: 1;
            }
        }
    }

    td {
        background-color: white;
    }
`

const ButtonWrap = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: .5rem;
    position: absolute;
    top: 5rem;
    right: 0;
    z-index: 10;
    width: 100%;
`

const ButtonStyled = styled.button`
    background-color: transparent;
    border: 0;
    height: 2rem;
    width: 2rem;
    overflow: hidden;
    font-size: 1rem;
    padding-left: 0;
    padding-right: 1rem;
    color: ${props => props.theme.colors.secondary};
    position: absolute;
    left: 160px;

    @media ${media.md} {
        left: 400px;
    }

    ${props => props.right && css`
        padding-right: 0;
        padding-left: 1rem;
        left: auto;
        right: 0;

        @media ${media.md} {
            left: auto;
            right: 0;
        }
    `}
`

const ModalStyled = styled(Modal)`
    .modal-body {
        padding: 0;
        border-bottom: 2px solid ${props => props.theme.colors.secondary};

        .includes {
            list-style: none;

            li {
                position: relative;
                padding: .25rem 0;
                &:before {
                    content: "";
                    position: absolute;
                    height: .5rem;
                    width: .5rem;
                    background-color: ${props => props.theme.colors.secondary};
                    left: -1.25rem;
                    top: .65rem;
                    border-radius: 50%;
                }
            }
        }
    }

    .close {
        position: absolute;
        top: .5rem;
        right: 1rem;
        color: ${props => props.theme.colors.secondary};
        z-index: 10;
        cursor: pointer;
    }
`

const FilterDropdown = styled(UncontrolledDropdown)`
    position: relative;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    
    button {
        min-width: 280px;
    }

    .dropdown-toggle {
        border: 1px solid ${props => props.theme.colors.secondary};
        background-color: white;
        padding: .75rem;
        color: ${props => props.theme.colors.secondary};
        font-family: ${props => props.theme.font.family.bold};
        text-transform: uppercase;
        font-size: ${props => props.theme.font.size.sm};
        position: relative;
        text-align: left;
        padding-right: 3rem;

        &:after {
            display: none;
        }

        .icon {
            position: absolute;
            right: 1rem;
            font-size: 1rem;
        }
    }

    .dropdown-menu {
        margin: 0;
        padding: 0;
        width: 100%;
        
        button {
            color: ${props => props.theme.colors.secondary};
            text-transform: uppercase;
            font-family: ${props => props.theme.font.family.bold};
            font-size: ${props => props.theme.font.size.sm};
            padding: 1rem;

            &:hover {
                color: white;
                background-color: ${props => props.theme.colors.secondary};
            }
        }
    }

    &.show {
        z-index: 20;
        .dropdown-toggle {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .icon {
                &:before {
                    transform: rotate(180deg);
                    display: block;
                }
            }
        }

        .dropdown-menu {
            border: 1px solid ${props => props.theme.colors.secondary};
            border-top: 0;
            border-radius: 0;
        }
    }
`

class TicketPhasing extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tableOverflowed: false,
            modal: [],
            filterText: "SELECT YOUR TICKET TYPE (ALL)",
            windowWidth: 320
        }

        this.thSize = {
            "sm" : {
                "thFirst" : 160,
                "thRegular" : 130
            },
            "lg" : {
                "thFirst" : 400,
                "thRegular" : 200
            }
        }

        this.setTableWidth = this.setTableWidth.bind(this)
        this.checkActivePhase = this.checkActivePhase.bind(this)
        this.sideScroll = this.sideScroll.bind(this)
        this.checkShadow = this.checkShadow.bind(this)
		this.toggleModal = this.toggleModal.bind(this)
		this.setThWidth = this.setThWidth.bind(this)
    } 

    componentDidMount() {
        this.windowSize()
        this.setTableWidth()
        
        this.scrollContainer.addEventListener('scroll', this.checkShadow, { passive: true })
    }

    componentWillUnmount() {
        this.scrollContainer.removeEventListener('scroll', this.checkShadow, { passive: true })
    }

    windowSize() {
        if (typeof window !== "undefined") {
            this.setState({
                windowWidth: window.outerWidth
            })

            this.checkActivePhase(window.outerWidth)
        }
    }

    toggleModal(i) {
        let modals = this.state.modal

        modals[i] = !this.state.modal[i]

		this.setState(({
			modal: modals
		}))
	}

    setTableWidth() {
        if (this.table !== undefined) {
            const th = this.table.getElementsByTagName("th")
            let tableWidth = 0
            const windowWidth = window.innerWidth

            for (let i = 0; i < th.length; i++) {
                const thType = th[i].getAttribute('data-th')
                let firstCol = this.thSize["sm"]["thFirst"]
                let otherCols = this.thSize["sm"]["thRegular"]

                if (windowWidth > 767) {
                    firstCol = this.thSize["lg"]["thFirst"]
                    otherCols = this.thSize["lg"]["thRegular"]
                }

                let thWidth = otherCols
                if (thType === "first") thWidth = firstCol

                tableWidth = parseInt(tableWidth) + parseInt(thWidth)
            }

            this.table.style.width = `${tableWidth}px`

            if (this.title !== undefined) {
                // Fix title widths when text is stretching table.
                setTimeout(() => {
                    const actualWidth = this.table.getElementsByTagName("table")[0].offsetWidth
                    if (actualWidth > tableWidth) {
                        this.title.style.width = `${actualWidth}px`
                    } else {
                        this.title.style.width = `${tableWidth}px`
                    }     
                }, 200);
            }
            
            if (tableWidth > this.container.offsetWidth) {
                this.setState({
                    tableOverflowed: true,
                    tableWidth,
                    containerWidth: this.container.offsetWidth
                })
            }
        }
    }

    checkActivePhase(windowSize) {
        if (windowSize < 768) {
            const phases = this.props.data.acfTicketPhasing.phases
            let steps = 0
            let stepActive = false
            for (let i = 0; i < phases.length; i++) {
                if (this.phaseStatus(phases[i].status, phases[i].activeDate, phases[i].deactivateDate) === "yes") {
                    stepActive = true
                    break
                }
                steps++
            }
            if (stepActive) {
                this.sideScroll("right", steps)
            }
        }
    }

    sideScroll(direction, preDistance=1) {
        const element = this.scrollContainer
        const speed = 25
        const step = 10

        let distance = (this.thSize["sm"]["thRegular"] * preDistance) - 10
        if (this.state.windowWidth > 767) distance = (this.thSize["lg"]["thRegular"] * preDistance) - 10

        let scrollAmount = 0

        let slideTimer = setInterval(() => {
            if (direction === 'left') {
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if (scrollAmount >= distance) {
                this.checkShadow()
                window.clearInterval(slideTimer);
            }
        }, speed);
    }

    checkShadow() {
        if (this.tableFixed !== undefined) {
            let widthDiff = this.state.tableWidth - this.state.containerWidth
            this.scrollContainer.scrollLeft > widthDiff - 10 ? this.scrollOuter.classList.add('hide-white-shadow') : this.scrollOuter.classList.remove('hide-white-shadow')
            this.scrollContainer.scrollLeft === 0 ? this.tableFixed.classList.remove('display-shadow') : this.tableFixed.classList.add('display-shadow')
        }
    }

    phaseStatus(status, activeDate, deactivateDate) {
        if (status === "yes" || status === "no" || status === "comingsoon") {
            return status
        }

        if (status === "date") {

            // return yes or no or comingsoon
            if (activeDate !== undefined && deactivateDate !== undefined) {
                const currentDateTimeTimestamp = new Date().getTime()
                const activeDateTimestamp = new Date(activeDate).getTime()
                const deactivateDateTimestamp = new Date(deactivateDate).getTime()

                // console.log(currentDateTimeTimestamp)
                // console.log(activeDateTimestamp)
                // console.log(new Date(deactivateDate))

                if (currentDateTimeTimestamp > activeDateTimestamp && currentDateTimeTimestamp < deactivateDateTimestamp) {
                    return "yes"
                }

                if (currentDateTimeTimestamp < activeDateTimestamp) {
                    return "comingsoon"
                }

                if (currentDateTimeTimestamp > deactivateDateTimestamp) {
                    return "no"
                }
            }
        }
    }

    setThWidth(type="regular") {
        let size = "sm"
        if (this.state.windowWidth > 767) size = "lg"

        let colSize = this.thSize[size]["thRegular"]
        if (type === "first") {
            colSize = this.thSize[size]["thFirst"]
        }

        return { width: `${colSize}px` }
    }

    setFilter(text, i) {
        this.setState({
            filterText: text
        })

        if (i !== -1) {
            const node = document.getElementById(`ticket${i}`)
            const headerHeight = document.getElementById('siteHeader').clientHeight
            const bodyPadding = document.body.classList.contains('mobile-book-tickets') ? 50 : 0

            scrollToElement(node, {
                offset: - (headerHeight + bodyPadding),
                duration: 500
            });

            // window.scrollTo({
            //     top: (node.getBoundingClientRect().top + window.scrollY) - (headerHeight + bodyPadding), // 
            //     behavior: 'smooth'
            // })
        }
    }

    render() {
		const phases = this.props.data.acfTicketPhasing.phases
		const ticketRows = this.props.data.acfTicketPhasing.ticketRows

        return (
            <ContainerStyled className="py-4 py-md-5">
                {this.props.data.acfTicketPhasing.displayFilter === "yes" &&
                    <FilterDropdown>
                        <DropdownToggle caret>
                            {this.state.filterText}
                            <span className="icon icon-down-open"></span>
                        </DropdownToggle>
                        
                        <DropdownMenu>
                            {this.state.filterText !== "SELECT YOUR TICKET TYPE (ALL)" && 
                                <DropdownItem 
                                    onClick={() => this.setFilter("SELECT YOUR TICKET TYPE (ALL)", -1)}
                                >
                                    SELECT YOUR TICKET TYPE (ALL)
                                </DropdownItem>
                            }
                            {ticketRows && ticketRows.map((ticket, i) => {
                                if (this.state.filterText !== ticket.title) {
                                    return (
                                        <DropdownItem 
                                            key={i}
                                            onClick={() => this.setFilter(ticket.title, i)}
                                        >
                                            {ticket.title}
                                        </DropdownItem>
                                    )
                                } else return ""
                            })}
                        </DropdownMenu>
                    </FilterDropdown>
                }

                <GeneralTableWrap 
                    className={classNames({
                        "py-4": true,
                        "tableOverflowed": this.state.tableOverflowed
                    })}
                    ref={container => this.container = container}
                >
                    {this.state.tableOverflowed &&
                        <ButtonWrap>
                            <ButtonStyled onClick={() => this.sideScroll('left')}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                                <span className="sr-only">Scroll back</span>
                            </ButtonStyled>
                            <ButtonStyled right onClick={() => this.sideScroll('right')}>
                                <FontAwesomeIcon icon={faChevronRight} />
                                <span className="sr-only">Scroll forwards</span>
                            </ButtonStyled>
                        </ButtonWrap>
                    }
                    <ScrollOuter ref={scrollOuter => this.scrollOuter = scrollOuter}>
                        <ScrollContainer ref={scrollContainer => this.scrollContainer = scrollContainer}>
                            <div ref={table => this.table = table}>
                                <TableStyled>
                                    <thead>
                                        <tr>
                                            <TicketInfo 
                                                as="th" 
                                                data-th="first" 
                                                style={this.setThWidth("first")} 
                                            />
                                            {phases && phases.map((phase, i) => {
                                                return(
                                                    <TicketHeading 
                                                        key={i} 
                                                        phase={phase} 
                                                        data-th="other" 
                                                        first={i === 0} 
                                                        last={i === (phases.length - 1)} 
                                                        status={this.phaseStatus(phase.status, phase.activeDate, phase.deactivateDate)}
                                                        style={this.setThWidth()} 
                                                    />
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ticketRows && ticketRows.map((ticket, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    <tr id={`ticket${i}`}>
                                                        <TicketInfo 
                                                            key={i} 
                                                            ticket={ticket}
                                                            toggleModal={() => this.toggleModal(i)}
                                                            first={i === 0} 
                                                            last={i === (ticketRows.length - 1)}
                                                        />
                                                        {ticket.ticketPhase && ticket.ticketPhase.map((phase, i) => {
                                                            return(
                                                                <TicketBlock 
                                                                    key={i} 
                                                                    phase={phase} 
                                                                    phases={phases} 
                                                                    soldOut={phase.soldOut} 
                                                                    status={this.phaseStatus(phases[i].status, phases[i].activeDate, phases[i].deactivateDate)} 
                                                                />
                                                            )
                                                        })}
                                                    </tr>
                                                    {ticket.hasPopup === "yes" && 
                                                        <ModalStyled
                                                            isOpen={this.state.modal[i]} 
                                                            toggle={() => this.toggleModal(i)} 
                                                            centered={true} 
                                                            size="lg">
                                                            <ModalBody>
                                                                <Container fluid>
                                                                    <button className="close" onClick={() => this.toggleModal(i)}>
                                                                        <FontAwesomeIcon icon={faTimes} />
                                                                    </button>
                                                                    <Row>
                                                                        <Col className="d-none d-md-block col-md-5 pl-0">
                                                                            {ticket.popupImage && 
                                                                                <GatsbyImage
                                                                                    image={ticket.popupImage.localFile.childImageSharp.gatsbyImageData}
                                                                                    alt={ticket.popupImage.altText} />  
                                                                            }
                                                                        </Col>
                                                                        <Col className="col-md-7 pt-3">
                                                                            <Text as="h3" uppercase secondary special xl>{ticket.title}</Text>
                                                                            <Text blue bold>{ticket.subTitle}</Text>
                                                                            <Text>{ticket.description}</Text>
                                                                            {ticket.popupTicketIncludes &&
                                                                                <ul className="includes">
                                                                                    {ticket.popupTicketIncludes.map((include, i) => {
                                                                                        return(
                                                                                            <li key={i}>
                                                                                                <Text>{include.text}</Text>
                                                                                            </li>
                                                                                        )
                                                                                    })}
                                                                                </ul>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </Container>
                                                            </ModalBody>
                                                        </ModalStyled>
                                                    }
                                                </React.Fragment>
                                            );
                                        })}
                                    </tbody>
                                </TableStyled>
                            </div>
                        </ScrollContainer>
                        {this.state.tableOverflowed &&
                        <div ref={tableFixed => this.tableFixed = tableFixed} style={{ pointerEvents: "none", width: `${this.state.tableWidth}px` }}>
                            <TableFixed
                                style={{ width: `${this.state.tableWidth}px` }}
                            >
                              <thead>
                                    <tr>
                                        <TicketInfo 
                                            as="th" 
                                            data-th="first" 
                                            style={this.setThWidth("first")} 
                                        />
                                        {phases && phases.map((phase, i) => {
                                            return(
                                                <TicketHeading 
                                                    key={i} 
                                                    phase={phase} 
                                                    data-th="other" 
                                                    first={i === 0} 
                                                    last={i === (phases.length - 1)} 
                                                    status={this.phaseStatus(phase.status, phase.activeDate, phase.deactivateDate)}
                                                    style={this.setThWidth()} 
                                                />
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {ticketRows && ticketRows.map((ticket, i) => {
                                        return(
                                            <React.Fragment key={i}>
                                                <tr>
                                                    <TicketInfo 
                                                        key={i} 
                                                        ticket={ticket}
                                                        toggleModal={this.toggleModal}
                                                        first={i === 0} 
                                                        last={i === (ticketRows.length - 1)} 
                                                    />
                                                    {ticket.ticketPhase && ticket.ticketPhase.map((phase, i) => {
                                                        return(
                                                            <TicketBlock 
                                                                key={i} 
                                                                phase={phase} 
                                                                phases={phases} 
                                                                soldOut={phase.soldOut} 
                                                                status={this.phaseStatus(phase.status, phase.activeDate, phase.deactivateDate)}
                                                            />
                                                        )
                                                    })}
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </TableFixed>
                        </div>
                        }
                    </ScrollOuter>
                </GeneralTableWrap>
                {this.props.data.acfTicketPhasing.bottom_note && 
                    <div dangerouslySetInnerHTML={{ __html: LinkSearchReplace(this.props.data.acfTicketPhasing.bottomNote)}} className="pb-4 pt-2" />
                }
            </ContainerStyled>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`{
  allWpTicketPhasingBlock {
    edges {
      node {
        databaseId
        acfTicketPhasing {
          version
          displayFilter
          phases {
            name
            status
            activeDate
            deactivateDate
          }
          ticketRows {
            title
            subTitle
            description
            hasPopup
            popupImage {
              altText
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 320, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                }
              }
            }
            popupTicketIncludes {
              text
            }
            ticketPhase {
              status
              soldOut
              smallTitle
              title
              price
              link
            }
          }
          bottomNote
        }
      }
    }
  }
}
`}
        render={data => {  
			return(
				data.allWpTicketPhasingBlock.edges.map(({ node }, i) => {   
					if (node.databaseId === props.block.ticketPhasingBlock.databaseId) {
                        if (node.acfTicketPhasing.version === "2") {
                            return (
                                <TicketPhasingV2 key={i} data={node} />
                            )
                        } else {
                            return (
                                <TicketPhasing key={i} data={node} />
                            )
                        }
					}
					return ""
				})
			)
        }}
    />
)