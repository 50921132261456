import React from "react"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import { media } from "utils/Media"
import classNames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image";
import ContainerMax from "components/shared/ContainerMax"
import Button from "components/shared/Button"
import WPLink from "components/shared/WPLink"

const ImageTextWrap = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${media.md} {
        min-height: calc(( (688 / 466) * (100vw / 2) ));
    }

    @media ${media.lg} {
        min-height: calc(( (650 / 785) * (100vw / 2) ));
    }

    @media ${media.xxl} {
        min-height: 650px;
    }
    
    @media ${media.xxxl} {
        min-height: 794px;
    }
`

const Content = styled.div`
    padding: ${props => props.theme.bootstrapGutterWidth};

    @media ${media.md} {
        padding: 1rem 0 0 calc(${props => props.theme.bootstrapGutterWidth} * 4);
    }

    &.image-right {
        @media ${media.md} {
            padding: 1rem calc(${props => props.theme.bootstrapGutterWidth} * 4) 0 0;
        }
    }

    h1, h2 {
        font-size: ${props => props.theme.font.h2.size};
        @media ${media.md} {
            font-size: ${props => props.theme.font.h1.size};
        }
    }
`

const ImageCol = styled(Col)`
    padding: 0;

    @media ${media.md} {
        position: absolute;
        left: 0;
        padding-left: 0;
        top: 0;
        max-height: 100%;
        overflow: hidden;
        /* padding-right: ${props => props.theme.bootstrapGutterWidth}; */
    }

    &.image-right {
        @media ${media.md} {
            left: auto;
            right: 0;
            padding-right: 0;
            /* padding-left: ${props => props.theme.bootstrapGutterWidth}; */
        }
    }
`


const ImageText = (props) => {

    let id = ""
    if (props.block.anchorId !== null) {
        id = props.block.anchorId.toLowerCase()
        id = props.block.anchorId.replace(' ', '')
    }

    return (
        <ContainerMax 
            id={id}
            maxWidth="1920"
            className={classNames({
                "py-4": props.block.padding === "yes",
                "px-md-0": true
            })}
        >
            <ImageTextWrap>
                {/* <Row> */}
                    <Container>
                        <Row className="align-items-center py-3">
                            <ImageCol md={6} className={classNames({ "image-right": props.block.imagePosition === "right"  })}>
                                <GatsbyImage
                                    image={props.block.imageTablet.localFile.childImageSharp.gatsbyImageData}
                                    className="d-none d-md-block d-lg-none" />                              
                                <GatsbyImage
                                    image={props.block.image.localFile.childImageSharp.gatsbyImageData}
                                    className="d-md-none d-lg-block" />     
                            </ImageCol>
                            <Col md={{ size: 6, offset: props.block.imagePosition === "right" ? 0 : 6 }}>
                                <Content className={classNames({ "image-right": props.block.imagePosition === "right" })}>
                                    <div dangerouslySetInnerHTML={{ __html: props.block.text }} className="py-4 pb-md-4 pt-md-0" />
                                    {
                                        props.block.links && props.block.links.map((link, i) => {
                                            if (
                                                link.__typename === "WpTemplate_FeatureArticle_Acfflexiblecontent_Blocks_ImageWithText_Links_Link" ||
                                                link.__typename === "WpDefaultTemplate_Acfflexiblecontent_Blocks_ImageWithText_Links_Link"
                                            ) {
                                                return (
                                                    <WPLink url={link.link.url} target={link.link.target} rel="noopener noreferrer" button color="primary" key={i} className="mr-2 mb-2">
                                                        {link.link.title}
                                                    </WPLink>
                                                )
                                            } else if (
                                                link.__typename === "WpTemplate_FeatureArticle_Acfflexiblecontent_Blocks_ImageWithText_Links_File" ||
                                                link.__typename === "WpDefaultTemplate_Acfflexiblecontent_Blocks_ImageWithText_Links_File"
                                            ) {
                                                return (
                                                    <Button href={link.file.mediaItemUrl} target="_blank" rel="noopener noreferrer" color="primary" key={i} className="mr-2 mb-2">
                                                        {link.linkText}
                                                    </Button>
                                                )
                                            }
                                            return null;
                                        })
                                    }
                                </Content>
                            </Col>
                        </Row>
                    </Container>
                {/* </Row> */}
            </ImageTextWrap>
        </ContainerMax>
    );
}

export default ImageText