import { UncontrolledDropdown } from "reactstrap"
import styled from "styled-components"

const FilterDropdown = styled(UncontrolledDropdown)`
    padding-bottom: 1.5rem;
    position: relative;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    
    button {
        min-width: 280px;
    }

    .dropdown-toggle {
        border: 1px solid ${props => props.theme.colors.secondary};
        background-color: white;
        padding: .75rem;
        border-radius: 0;
        color: ${props => props.theme.colors.secondary};
        font-family: ${props => props.theme.font.family.bold};
        text-transform: uppercase;
        font-size: ${props => props.theme.font.size.sm};
        position: relative;
        text-align: left;
        padding-left: 1rem;

        &:after {
            display: none;
        }

        .icon {
            position: absolute;
            right: 1rem;
            font-size: 1rem;
        }
    }

    .dropdown-menu {
        margin: 0;
        padding: 0;
        width: 100%;
        
        button {
            color: ${props => props.theme.colors.secondary};
            text-transform: uppercase;
            font-family: ${props => props.theme.font.family.bold};
            font-size: ${props => props.theme.font.size.sm};
            padding: 1rem;

            &:hover {
                color: white;
                background-color: ${props => props.theme.colors.secondary};
            }
        }
    }

    &.show {
        z-index: 10;
        .dropdown-toggle {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .icon {
                &:before {
                    transform: rotate(180deg);
                    display: block;
                }
            }
        }

        .dropdown-menu {
            border: 1px solid ${props => props.theme.colors.secondary};
            border-top: 0;
            border-radius: 0;
        }
    }
`

export default FilterDropdown