import React from "react"
import { Container, Row, Col } from "reactstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { media } from "utils/Media"

const StyledSchedule = styled.div`
  .date {
    background-color: #FCF3EC;
    padding: 1rem;
    height: 100%;

    &__date {
      color: ${props => props.theme.colors.secondary};
      font-size: 3.5rem;
      font-family: ${props => props.theme.font.family.bold};
      text-transform: uppercase;
      line-height: 1;

      &--small {
        font-size: 1rem;
      }
    }

    &__title {
      font-size: 2rem;
      line-height: 1.2;
      font-family: ${props => props.theme.font.family.bold};
    }

    &__subtitle {

    }
  }

  .event {
    padding: 15px 30px;

    @media ${media.xl} {
      padding: 15px;
      padding-bottom: 0;
    }

    &__title {
      font-size: ${props => props.theme.font.size.lg};
    }
  }
`

export default function Schedule({ block }) {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  return(
    <Container className="py-4">
      {block.schedule?.map((schedule) => {
        return(
          <StyledSchedule key={schedule.id} className="pb-4">
            <h2 className="text-uppercase pt-2 pb-4">{schedule.title}</h2>
            {schedule.acfSchedule?.dates?.map((d, i) => {
              const {
                date,
                title,
                subTitle,
                events,
                image
              } = d
              const dateObj = new Date(date)

              return(
                <Row key={schedule.id+i} className="pb-4">
                  <Col xs={12} xl={3}>
                    <div className="date">
                      <Row>
                        <Col className="date__date pb-3 pb-md-0 pb-xl-3" md={7} xl={12}>
                          <div className="pb-2">{dateObj.getDate()}</div>
                          <div className="date__date--small pb-1">{days[dateObj.getDay()]}</div>
                          <div className="date__date--small">{`${months[dateObj.getMonth()]} ${dateObj.getFullYear()}`}</div>
                        </Col>
                        <Col md={5} xl={12}>
                          {title && (
                            <div className="date__title pb-1">{title}</div>
                          )}
                          {subTitle && (
                            <div className="date__subtitle">{subTitle}</div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={12} md={7} xl={5} className="event">
                    {events?.map((event, ei) => {
                      console.log(events.length)
                      return(
                        <div key={schedule.id+i+ei} className={`${events.length !== (ei + 1) ? "pb-3" : ""}`}>
                          {event.time && (
                            <div className="event__time" dangerouslySetInnerHTML={{ __html: event.time}} />
                          )}
                          {event.title && (
                            <div className="event__title" dangerouslySetInnerHTML={{ __html: event.title}} />
                          )}
                        </div>
                      )
                    })}
                  </Col>
                  <Col xs={12} md={5} xl={4} className="d-none d-md-block">
                    {image && (
                      <GatsbyImage image={image.localFile.childImageSharp.gatsbyImageData} alt={image.altText} />
                    )}
                  </Col>
                </Row>
              )
            })}
          </StyledSchedule>
        )
      })}
    </Container>
  )
}