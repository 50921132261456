import React from "react"
import { Row, Col } from "reactstrap"
import SidebarBlock from "components/shared/SidebarBlock"
// import RolexApp from "components/shared/RolexApp"
// import WeatherApp from "components/shared/WeatherApp"
import Block5 from "./Block5"

const DesktopView = (props) => {
    return(
        <div className="d-none d-xl-block">
            <Row>
                <Col lg={6}>
                    <Row>
                        <Col lg={6} className="p-0">
                            <SidebarBlock id={props.data.block1.databaseId} linkBlock={true} color="tertiary" />
                        </Col>
                        <Col lg={6} className="p-0">
                            <SidebarBlock id={props.data.block2.databaseId} linkBlock={true} color="secondary" />
                        </Col>
                        <Col lg={6} className="p-0">
                            <SidebarBlock id={props.data.block3.databaseId} linkBlock={true} color="secondary" />
                        </Col>
                        <Col lg={6} className="p-0">
                            <SidebarBlock id={props.data.block4.databaseId} linkBlock={true} color="tertiary" />
                        </Col>
                    </Row>
                </Col>
                <Col lg={6}>
                    <Block5 data={props.data.block5} />
                </Col>
                <Col lg={3} className="p-0">
                    <SidebarBlock id={props.data.block6.databaseId} linkBlock={true} color="tertiary"/>
                </Col>
                <Col lg={3} className="p-0">
                    {/* <WeatherApp /> */}
                    <SidebarBlock id={props.data.block7.databaseId} linkBlock={true} color="secondary" />
                </Col>
                <Col lg={3} className="p-0">
                    <SidebarBlock id={props.data.block8.databaseId} linkBlock={true} color="tertiary"/>
                </Col>
                <Col lg={3} className="p-0">
                    <SidebarBlock id={props.data.block9.databaseId} linkBlock={true} color="secondary"/>
                </Col>
            </Row>
        </div>
    )
}

export default DesktopView